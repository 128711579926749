import React, { memo, useState, useEffect } from "react";
import ServicesContext from "../../../contexts/services.context";
import { inject } from "js-react-components";
import { e } from "../../../utils/misc-utils-functions";
import {Chip, Grid, CircularProgress} from "@material-ui/core";

const ValueRenderer = ({value, onRemove, attributeValueService, errorService}) => {
    const [el, setEl] = useState(null);

    useEffect(() => {
        attributeValueService
            .serve("get", null, {filterAttribute: "id", filterValue: value})
            .then(v => setEl(v instanceof Array ? v[0] : v));
    }, [value]);

    return el ? <Chip
        label={el.full_value} 
        onDelete={onRemove && (() => onRemove(value))} 
    /> : <CircularProgress size="1em" />;
}

export default ValueRenderer;