export function e(a, b) {
    if((typeof(a) === typeof(b)) && (typeof(a) === "object")) {
        return JSON.stringify(a) === JSON.stringify(b);
    } else {
        return a === b;
    }
}

export function rand() {
    return Math.floor(Math.random()*9999999+1);
}

export function getServiceArgs({endpoint, requestManager}) {
    return {
        path: endpoint,
        keyGetter: c => c.id,
        Constructor: function(v) {return v;},
        requestManager
    }
}

/**
 * @param {string} stuff 
 * @param {number} maxLength 
 */
export function shorten(stuff, maxLength, noDots) {
    if(!stuff || (stuff.length < maxLength)) {
        return stuff; 
    } else {
        stuff = stuff.substring(0, maxLength - 3);
        if(!noDots) {
            stuff += "...";
        }
        return stuff;
    }
}

/**
 * @param {number} v 
 * @param {number} n 
 */
export function round(v, n) {
    n = Math.round(n);
    if(isNaN(n)) {
        return null;
    }
    if(n > 0) {
        const fact = Math.pow(10, n);
        return Math.round(v * fact) / fact;
    } else {
        throw Error("invalid_number_digits");
    }
}