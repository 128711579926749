import React, {useState, useEffect} from "react";
import ServicesContext from "../../../contexts/services.context";
import {inject} from "js-react-components";
import {Delete} from "@material-ui/icons";
import { Box, makeStyles, Collapse, IconButton, Typography, Fade } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    img: {
        display: "block",
        maxWidth: "100%",
        boxSizing: "border-box",
        zIndex: 1,
        transitionProperty: "opacity",
        transitionDuration: "500ms"
    },
    wrapper: {
        position: "relative"
    },
    menu: {
        position: "absolute"
    },
    menuWrapper: {
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "rgb(0, 0, 0, 0.65)",
        zIndex: 2,
        padding: "0.25em 0"
    },
    imgHover: {
        opacity: 0.65
    },
    button: {
        color: "white"
    },
    text: {
        color: "white",
        marginLeft: "0.5em"
    }
}));

const Renderer = inject(
    ServicesContext,
    ["image", "errorService"],
    /**
     * @param {{
     *      id: number,
     *      onRemove: (id: number) => void
     * }} param0
     */
    ({id, onRemove, image, errorService, testId}) => {
        const [el, setEl] = useState(null);
        const [menu, setMenu] = useState(null);
        const classes = useStyles();
    
        useEffect(() => {
            image.serve("get", null, {filterAttribute: "id", filterValue: id})
                 .then(v => setEl(v instanceof Array ? v[0] : v));
        }, [id]);
    
        return el ? <Box 
            className={classes.wrapper} 
            onMouseEnter={evt => setMenu(evt.target)}
            onMouseLeave={() => setMenu(null)}
        >
            <Fade in={Boolean(menu)}>
                <Box className={classes.menuWrapper}>
                    <Typography component="p" variant="body1" className={classes.text}>
                        {el.name}
                    </Typography>
                    <IconButton size="small" onClick={onRemove} className={classes.button}>
                        <Delete />
                    </IconButton>
                </Box>
            </Fade>
            <img 
                src={el.content || el.url || el.full_url} 
                alt={el.name} 
                className={clsx([classes.img, menu && classes.imgHover])}
            />
        </Box> : null;
    }
);

export default Renderer;