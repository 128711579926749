import React, { Fragment } from "react";
import {Switch, Route, withRouter, Redirect} from "react-router-dom";
import HistoryWrapper from "./components/auxiliary/history-wrapper/history-wrapper.component";
import ProductVariantWrapper from "./components/product/auxiliary/product-variant-wrapper.component";
import ProductAdder from "./components/product/entity-editors/product-adder/product-adder.component";
import ProductQuantityAlterationWrapper from "./components/product-quantity-alteration/auxiliary/product-quantity-alteration-wrapper";
import AsyncEntityLoader from "./components/auxiliary/async-entity-loader/async-entity-loader.component";
import BaseProductEditor from "./components/product/entity-editors/base-product/base-product-editor.component";
import { inject } from "js-react-components";
import ServicesContext from "./contexts/services.context";
import { CircularProgress, List, Modal } from "@material-ui/core";
import ProductVariantEditor from "./components/product/entity-editors/product-variant/product-variant-editor.component";
import BaseProductVariantList from "./components/product/entity-renderers/base-product-variant-list.component";
import WIP from "./components/auxiliary/wip.component";
import AuthWrapper from "./components/auth/auth-wrapper.component";
import SingerDashboard from "./components/singer-dashboard.component";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import "./transitions.css";
import {ExpiredRefreshHandler} from "react-auth-components";
import UnauthMex from "./components/auxiliary/unauthorized-message.component";

const ProdEditRouteRenderer = ({match, components}) => {
    const k = parseInt(match.params.id);
    return <HistoryWrapper
        style={{position: "relative"}}
        Component={components.ProductEditor}
        ComponentProps={{
            key: k,
            filterAttribute: "id",
            filterValue: k
        }}
        links={[
            {link: "/product", label: "Prodotti"},
            {link: `/product/${k}`, label: `${k}`}
        ]}
    />;
};
const getProdRouteRenderer = components => (props) => <ProdEditRouteRenderer 
    components={components} 
    {...props} 
/>;
const ProdVarListRouteRenderer = ({entity}) => entity && <HistoryWrapper
    style={{position: "relative"}}
    Component={({entity}) => <List style={{width: "100%"}}>
        <BaseProductVariantList element={entity} collapsed={false} />
    </List>}
    ComponentProps={{entity}}
    links={[
        {link: "/variable_product", label: "Prodotti"},
        {link: `/variable_product/${entity.id}`, label: `Prodotto ${entity.id}`},
        {link: `/variable_product/${entity.id}/variants`, label: `Varianti`}
    ]}
/>;

/*

<TransitionGroup>
        <CSSTransition
            key={(location.pathname !== "/" ? location.pathname.split('/')[1] : "/")}
            classNames="fade"
            timeout={1000}
        >
            
        </CSSTransition>
    </TransitionGroup>

    */

const RoutesRenderer = withRouter(
    ({services, components, requestManager, location}) => <Fragment>
        <Switch location={location}>
            <Route path="/" exact>
                <AuthWrapper
                    LoginPanel={components.LoginPanel}
                    Gate={components.Gate}
                    Auth={components.Auth}
                    onLogin={c => {}}
                    service={services.authService}
                    LoginPanelProps={{
                        title: "Login utente",
                        rememberLabel: "Ricordami"
                    }}
                    loginPath="/dashboard"
                    alertService={services.alertService}
                    ExpiredRefreshHandler={ExpiredRefreshHandler}
                />
            </Route>
            <Route 
                path="/dashboard"
                exact
            >
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={SingerDashboard}
                    links={[
                        {link: "/dashboard", label: "Dashboard"},
                    ]}
                />
            </Route>
            <Route 
                path="/attribute"
                exact
            >
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.Attribute}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc"
                    }}
                    links={[
                        {link: "/attribute", label: "Attributi"}
                    ]}
                />
            </Route>
            <Route 
                path="/alert"
                exact
            >
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.AlertBaseComponent}
                    ComponentProps={{
                        sortAttribute: "created_at",
                        sortDirection: "desc"
                    }}
                    links={[
                        {link: "/alert", label: "Notifiche e operazioni"}
                    ]}
                />
            </Route>
            <Route
                path="/category" 
                exact
            >
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.Category}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc",
                        defaultView: "list"
                    }}
                    links={[
                        {link: "/category", label: "Categorie"}
                    ]}
                />
            </Route>
            <Route path="/image" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.Image}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc"
                    }}
                    links={[
                        {link: "/image", label: "Immagini"}
                    ]}
                />
            </Route>
            <Route path="/product" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.Product}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc"
                    }}
                    links={[
                        {link: "/product", label: "Prodotti"}
                    ]}
                />
            </Route>
            <Route path="/customer" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.Customer}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc"
                    }}
                    links={[
                        {link: "/customer", label: "Clienti"}
                    ]}
                />
            </Route>
            <Route path="/basket" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.Basket}
                    ComponentProps={{
                        sortAttribute: "updated_at",
                        sortDirection: "desc"
                    }}
                    links={[
                        {link: "/basket", label: "Carrelli"}
                    ]}
                />
            </Route>
            <Route 
                path="/basket/stats"
                exact
            >
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.BasketStat}
                    links={[
                        {link: "/basket", label: "Carrelli"},
                        {link: "/basket/stats", label: "Statistiche"}
                    ]}
                />
            </Route>
            <Route path="/attribute/:id" exact component={({match}) => {
                const k = parseInt(match.params.id);
                return <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.AttributeEditor}
                    ComponentProps={{
                        key: k,
                        filterAttribute: "id",
                        filterValue: k
                    }}
                    links={[
                        {link: "/attribute", label: "Attributi"},
                        {link: `/attribute/${k}`, label: `${k}`}
                    ]}
                />;
            }} />
            <Route path="/basket/:id" exact component={({match}) => {
                const k = parseInt(match.params.id);
                return <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.BasketEditor}
                    ComponentProps={{
                        key: k,
                        filterAttribute: "id",
                        filterValue: k
                    }}
                    links={[
                        {link: "/basket", label: "Carrelli"},
                        {link: `/basket/${k}`, label: `${k}`}
                    ]}
                />;
            }} />
            <Route path="/product_variant" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={ProductVariantWrapper}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc"
                    }}
                    links={[
                        {link: "/product_variant", label: "Varianti prodotto"}
                    ]}
                />
            </Route>
            <Route 
                path="/category/:cid/products" 
                exact
                component={({match}) => {
                    const k = parseInt(match.params.cid);
                    return <HistoryWrapper
                        style={{position: "relative"}}
                        Component={components.Product}
                        ComponentProps={{
                            filterAttribute: "category_id",
                            filterValue: k
                        }}
                        links={[
                            {link: "/category", label: "Clienti"},
                            {link: `/category/${k}`, label: `Categoria ${k}`},
                            {link: `/category/${k}/products`, label: `Prodotti`}
                        ]}
                    />;
                }}
            />
            <Route path="/variable_product" exact>
                <HistoryWrapper
                    Component={components.BaseProduct}
                    ComponentProps={{
                        sortAttribute: "name",
                        sortDirection: "asc"
                    }}
                    links={[
                        {link: "/variable_product", label: "Prodotti base"}
                    ]}
                />
            </Route>
            <Route path="/quantity_alteration" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={ProductQuantityAlterationWrapper}
                    links={[
                        {link: "/quantity_alteration", label: "Caricamenti e resi prodotti"}
                    ]}
                />
            </Route>
            <Route path="/product/add" exact>
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={ProductAdder}
                    ComponentProps={{
                        alertService: services.alertService,
                        service: services.product
                    }}
                    links={[
                        {link: "/product", label: "Prodotti"},
                        {link: "/product/add", label: "Aggiunta prodotto"}
                    ]}
                />
            </Route>
            <Route
                path="/variable_product/:id/variants"
                exact
                component={({match}) => <AsyncEntityLoader
                    id={parseInt(match.params.id)}
                    onGet={id => services.variableProduct.serve("get", null, {filterAttribute: "id", filterValue: id})}
                    Renderer={ProdVarListRouteRenderer}
                    LoadingComponent={CircularProgress}
                />}
            />
            <Route
                path="/variable_product/:id"
                exact
                component={getProdRouteRenderer(components)}
            />
            <Route
                path="/product_variant/:id"
                exact
                component={getProdRouteRenderer(components)}
            />
            <Route 
                path="/product/stats"
                exact
            >
                <HistoryWrapper
                    style={{position: "relative"}}
                    Component={components.ProductStat}
                    links={[
                        {link: "/product", label: "Prodotti"},
                        {link: "/product/stats", label: "Statistiche"}
                    ]}
                />
            </Route>
            <Route
                path="/product/:id"
                exact
                component={getProdRouteRenderer(components)}
            />
            <Route 
                path="/customer/:cid/baskets" 
                exact
                component={({match}) => {
                    const k = parseInt(match.params.cid);
                    return <HistoryWrapper
                        style={{position: "relative"}}
                        Component={components.Basket}
                        ComponentProps={{
                            filterAttribute: "customer_id",
                            filterValue: k
                        }}
                        links={[
                            {link: "/customer", label: "Clienti"},
                            {link: `/customer/${k}`, label: `Cliente ${k}`},
                            {link: `/customer/${k}/baskets`, label: `Carrelli`}
                        ]}
                    />;
                }}
            />
            <Route 
                path="/variable_product/:id/variant/add" 
                exact
                component={({match}) => {
                    const k = parseInt(match.params.id);
                    return <HistoryWrapper
                        style={{position: "relative"}}
                        Component={ProductAdder}
                        ComponentProps={{
                            service: services.product,
                            alertService: services.alertService,
                            onClose: () => window.history.back(),
                            product: {variable_product_id: k, type: "variant"},
                            FirstStepProps: {fixedType: true}
                        }}
                        links={[
                            {link: "/variable_product", label: "Prodotti"},
                            {link: `/product/${k}`, label: `Prodotto ${k}`},
                            {link: `/variable_product/${k}/variant/add`, label: `Aggiunta variante`}
                        ]}
                    />;
                }}
            />
        </Switch>
        <ExpiredRefreshHandler
            Component={({onRequestToken}) => <Modal open>
                <components.LoginPanel
                    title="Login"
                    rememberLabel="Ricordami"
                    onSubmit={onRequestToken}
                />
            </Modal>}
        />
    </Fragment>
);

export default function renderMainRoutes({services, components, requestManager}) {
    return <RoutesRenderer 
        services={services} 
        components={components} 
        requestManager={requestManager} 
    />;
}