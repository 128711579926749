import React, { useState } from "react";
import {
  Grid,
  Container,
  Collapse,
  Typography,
  Paper,
  Box,
  withStyles,
  Drawer
} from "@material-ui/core";
import SingerAppBar from "./singer-app-bar.component";
import SingerSideView from "./side-view/singer-side-view.component";
import ComponentsContext from "../contexts/components.context";
import ServicesContext from "../contexts/services.context";
import HorizontalCollapse from "./auxiliary/horizontal-collapse.component";
import {BasketSideView, BasketSideViewProvider} from "../components/basket/side-view/basket-side-view.component";
import clsx from "clsx";
import {HashRouter as Router, Switch, Link, Route} from "react-router-dom";
import AlertComponent from "./auxiliary/alert-component/alert-component";
import settingsContext from "../contexts/settings.context";
import { Delete, DeleteForever, Close } from "@material-ui/icons";

export default withStyles({
  content: {
    backgroundColor: "white"
  },
  flex: {
    display: "flex"
  },
  overflowXHidden: {
    overflowX: "hidden"
  },
  hiddenOverflow: {
    overflow: "hidden"
  },
  mainContainerWrapper: {
    background: "white",
    flex: 1,
    padding: "1em"
  },
  mainContainerWrapperMargin: {
    marginTop: "0.5em",
    marginBottom: "0.5em"
  }
})(({ classes, children }) => {
  const [state, setState] = useState({
    sideMenu: false, 
    basketView: false
  });
  const toggleSideMenu = () => setState(s => ({
    ...s, sideMenu: !s.sideMenu,
  }));
  const toggleBasketView = () => setState(s => ({
    ...s, basketView: !s.basketView,
  }));
  return (
      <ComponentsContext.Consumer>
        {(components) => <ServicesContext.Consumer>
          {(services) => <settingsContext.Consumer>
            {({smallScreen, appBarHeight, backgroundColor, username}) => <Container maxWidth={false} disableGutters>
              <components.DeleteDialog service={services.deleteDialogService} />
              <BasketSideViewProvider service={services.basketSideView}>
                <Box display="flex" flexDirection="column">
                  <components.Gate
                    role="admin"
                    onAuthorized={() => <SingerAppBar
                      AppBarProps={{ position: "sticky" }}
                      buttons={false}
                      onMenuClick={toggleSideMenu}
                      title={!smallScreen && "Centro Singer Arezzo"}
                      inlineLogo
                      onBasketClick={toggleBasketView}
                      basketTriggerDisabled
                      basketSideView={services.basketSideView}
                    />}
                    onMissing={() => <SingerAppBar
                      AppBarProps={{ position: "sticky" }}
                      title={!smallScreen && "Centro Singer Arezzo"}
                      inlineLogo
                      buttons={false}
                      basketSideView={services.basketSideView}
                    />}
                  />
                  <Box flex={1}>
                    {smallScreen && <Drawer 
                      open={state.sideMenu} 
                      anchor="left" 
                      onBackdropClick={toggleSideMenu}
                    >
                      <Box maxWidth="calc(min(350px, 85vw))">
                        <SingerAppBar
                          AppBarProps={{ position: "sticky" }}
                          buttons={false}
                          onMenuClick={toggleSideMenu}
                          MenuIcon={Close}
                          basketSideView={services.basketSideView}
                        />
                        <SingerSideView 
                          onAfterLogout={() => {
                            setState({sideMenu: false, basketView: false});
                            services.windowService.href("#/");
                          }} 
                        />
                      </Box>
                    </Drawer>}
                    {smallScreen && <Drawer 
                      open={state.basketView} 
                      anchor="right"
                      onBackdropClick={toggleBasketView}
                    >
                      <Box maxWidth="calc(min(350px, 85vw))">
                        <SingerAppBar
                          AppBarProps={{ position: "sticky" }}
                          buttons={false}
                          onBasketClick={toggleBasketView}
                          BasketIcon={Close}
                          basketSideView={services.basketSideView}
                        />
                        <BasketSideView />
                      </Box>
                    </Drawer>}
                    <Grid container style={{flexWrap: "no-wrap"}}>
                      {!smallScreen && <Grid item className={classes.flex}>
                        <Box flex={1} display="flex">
                          <components.Gate
                            role="admin"
                            onAuthorized={() => <HorizontalCollapse
                              collapsed={state.sideMenu}
                              duration="500ms"
                              width="450px"
                            >
                              <SingerSideView 
                                onAfterLogout={() => {
                                  setState({sideMenu: true, basketView: false});
                                  services.windowService.href("#/");
                                }}
                              />
                            </HorizontalCollapse>}
                          />
                        </Box>
                      </Grid>}
                      <Grid item xs style={{display: "flex", flexDirection: "column"}}>
                        <Container
                          maxWidth="lg"
                          disableGutters={smallScreen}
                          className={clsx([classes.content])}
                          style={{
                            backgroundColor, 
                            flex: 1, 
                            display: "flex",
                            minHeight: "calc(100vh - 67px)"
                          }}
                        >
                          <Paper 
                            justifyContent="center" 
                            id="main-content-wrapper"
                            className={clsx([
                              !smallScreen && classes.mainContainerWrapperMargin, 
                              classes.mainContainerWrapper
                            ])}
                          >
                            {children}
                          </Paper>
                        </Container>
                      </Grid>
                      <Drawer 
                        open={state.basketView} 
                        anchor="right" 
                        onBackdropClick={toggleBasketView}
                      >
                        <Paper 
                          style={{
                            position: "sticky",
                            height: `calc(100vh)`,
                            overflowX: "hidden"
                          }}
                        >
                          <Container 
                            maxWidth="md" 
                            disableGutters 
                            style={{height: "100%"}}
                          >
                            <SingerAppBar
                              AppBarProps={{ position: "sticky" }}
                              buttons={false}
                              onBasketClick={toggleBasketView}
                              BasketIcon={Close}
                              title="Carrello attivo"
                              imgMaxWidth="200px"
                            />
                            <Box padding="1em 0" height={`calc(100% - ${appBarHeight}px - 2em)`}>
                              <BasketSideView />
                            </Box>
                          </Container>
                        </Paper>
                      </Drawer>
                    </Grid>
                  </Box>
                </Box>
              </BasketSideViewProvider>
              <AlertComponent 
                service={services.alertService} 
                style={{
                  position: "fixed", 
                  top: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 9999999
                }} 
                hideTime={10000}
              />
            </Container>}  
          </settingsContext.Consumer>}  
        </ServicesContext.Consumer>}
      </ComponentsContext.Consumer>
  );
});
