import React, { memo, useState } from "react";
import {Typography, Grid, Container, Button, makeStyles} from "@material-ui/core";
import {shorten} from "../../utils/misc-utils-functions";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: "32px",
        "& > .MuiButton-label": {
            maxWidth: "32px",
            fontWeight: "bold"
        }
    }
}));

const Component = ({text, length, TypographyProps}) => {

    const classes = useStyles();
    const [shortened, setShortened] = useState(false);
    const toggleShortened = () => setShortened(s => !s);

    const needsShortening = text.length > length;
    const shortenedText = !shortened && needsShortening ? shorten(text, length, true) : text;

    return <Container maxWidth={false} disableGutters>
        <Grid container alignItems="center">
            <Grid item>
                <Typography component="span" {...TypographyProps}>
                    {shortenedText}
                </Typography>
            </Grid>
            {needsShortening && <Grid item>
                <Button 
                    type="button" 
                    variant="text" 
                    color="primary" 
                    onClick={toggleShortened}
                    className={clsx(["name-shortener-more", classes.button])}
                    style={{
                        padding: 0
                    }}
                >
                    {!shortened ? "..." : "x"}
                </Button>
            </Grid>}
        </Grid>
    </Container>
}

export default memo(Component, (p, n) => p.text === n.text);