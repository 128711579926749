import React, { useState } from "react";
import ProductMeta, {Product, validate} from "../../../domain/product";
import {wrapEdit, ReactComponent, EntityEditWrapperInnerComponent, inject} from "js-react-components";
import {Grid, FormControl, FormLabel, TextField, Button, InputAdornment, IconButton, Tooltip, withStyles, Container, CircularProgress} from "@material-ui/core";
import { BarChart, Euro } from "@material-ui/icons";
import ServicesContext from "../../../contexts/services.context";
import { EntityService } from "js-generic-utilities";
import NameShortener from "../../auxiliary/name-shortener.component";

const CostTextField = withStyles({
    euro: {
        color: "rgb(111, 111, 111)"
    }
})(
    ({element, id, label, onInputChange, baseId, classes, invalid}) => {
        const [controlled, setControlled] = useState(true);
        return <TextField
            fullWidth
            error={invalid}
            helperText={invalid && "Costo mancante o non valido"}
            variant="outlined"
            label={label}
            type="number"
            onFocus={() => setControlled(false)}
            onBlur={() => setControlled(true)}
            defaultValue={controlled ? "" : element[id]}
            value={!controlled ? undefined : element[id]}
            onChange={e => onInputChange(id, e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment>
                    <Euro fontSize="small" className={classes.euro} />
                </InputAdornment>,
                min: 0,
                step: 0.1,
                "data-testid": `${baseId}-${id}`
            }}
        />;
    }
); 

/**
 * @type {ReactComponent<EntityEditWrapperInnerComponent<Product>>}
 */
const ProductDetailsEntityEditor = ({element, onSubmit, onInputChange, product, onElementOp}) => {
    const [state, setState] = useState({controlledCode: true, loading: false});
    const onFormSubmit = evt => {
        evt.preventDefault();
        onSubmit(element);
    }
    const onCodeGenerate = () => {
        setState(s => {
            return {...s, loading: true};
        })
        product.serve("generate_code")
            .then(({code}) => onInputChange("code", code))
            .finally(() => {
                setState(s => {
                    return {...s, loading: false};
                })
            });
    };
    const switchControlledCode = () => setState(s => {
        return {...s, controlledCode: !s.controlledCode};
    });
    if(element) {
        const baseId = `product-${element.id}-details-editor`;
        const invalids = {
            name: element && !validate("name", element),
            code: element && !validate("code", element),
            cost: element && !validate("cost", element),
            woocommerce_cost: element && !validate("woocommerce_cost", element),
            width: element && !validate("width", element),
            height: element && !validate("height", element),
            depth: element && !validate("depth", element),
            weight: element && !validate("weight", element),
        };
        return <FormControl 
            component="form" 
            onSubmit={onFormSubmit} 
            data-testid={`${baseId}`}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Container maxWidth={false} disableGutters>
                        <Grid container spacing={2}>
                            {/*<Grid item xs={12}>
                                <FormLabel>Nome e codice</FormLabel>
    </Grid>*/}
                            <Grid item xs={12} sm={6} key="name">
                                <TextField
                                    label="Nome"
                                    defaultValue={element.name}
                                    onChange={evt => onInputChange("name", evt.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        "data-testid": `${baseId}-name`
                                    }}
                                    error={invalids.name}
                                    helperText={invalids.name && "Nome mancante o non valido"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} key="code">
                                <TextField
                                    label="Codice"
                                    defaultValue={(!state.controlledCode && element.code) || ""}
                                    value={!state.controlledCode ? undefined : element.code}
                                    onChange={evt => onInputChange("code", evt.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    error={invalids.code}
                                    helperText={invalids.code && "Codice mancante o non valido"}
                                    InputProps={{
                                        "data-testid": `${baseId}-code`,
                                        onFocus: switchControlledCode,
                                        onBlur: switchControlledCode,
                                        endAdornment: <InputAdornment>
                                            {!state.loading ? <Tooltip 
                                                arrow
                                                aria-label={`${baseId}-code-generation-tooltip`}
                                                title="Premi per generare automaticamente il codice"
                                            >
                                                <Button 
                                                    disabled={state.loading}
                                                    data-testid={`${baseId}-generate-code`}
                                                    onClick={onCodeGenerate}
                                                    variant="text"
                                                    color="primary"
                                                >
                                                    Genera
                                                </Button>
                                            </Tooltip> : <CircularProgress size="1em" />}
                                        </InputAdornment>
                                    }}
                                />
                            </Grid>
                            {/*<Grid item xs={2}>
                                <TextField
                                    label="Stock"
                                    type="number"
                                    onChange={evt => onInputChange("quantity", evt.target.value)}
                                    defaultValue={element.quantity}
                                    fullWidth
                                />
                                </Grid>*/}
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth={false} disableGutters>
                        <Grid container spacing={2}>
                            {/*<Grid item xs={12}>
                                <FormLabel>Descrizione e dimensioni</FormLabel>
                                </Grid>*/}
                            <Grid item xs={12} sm={6} key="desc">
                                <TextField
                                    label="Descrizione"
                                    multiline
                                    rows={4}
                                    defaultValue={element.description}
                                    onChange={evt => onInputChange("description", evt.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        "data-testid": `${baseId}-description`
                                    }}
                                    inputProps={{
                                        style: {
                                            overflow: "auto",
                                            resize: "both"
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} key="desc-short">
                                <TextField
                                    label="Descrizione breve"
                                    multiline
                                    rows={4}
                                    defaultValue={element.short_description}
                                    onChange={evt => onInputChange("short_description", evt.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        "data-testid": `${baseId}-short-description`
                                    }}
                                    inputProps={{
                                        style: {
                                            overflow: "auto",
                                            resize: "both"
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} key="dims">
                                <Container maxWidth={false} disableGutters>
                                    <Grid container spacing={2}>
                                        {[
                                            {id: "weight", label: "Peso (g)"},
                                            {id: "height", label: "Altezza (cm)"},
                                            {id: "width", label: "Larghezza (cm)"},
                                            {id: "depth", label: "Profondità (cm)"}
                                        ].map(({label, id}) => <Grid item xs={6} sm={3} key={id}>
                                            <TextField
                                                type="number"
                                                inputProps={{
                                                    min: 0,
                                                    step: 0.01
                                                }}
                                                error={invalids[id]}
                                                helperText={invalids[id] && `${label} mancante o non valido.`}
                                                label={label}
                                                defaultValue={element[id]}
                                                onChange={e => onInputChange(id, e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                InputProps={{
                                                    "data-testid": `${baseId}-${id}`
                                                }}
                                            />
                                        </Grid>)}
                                    </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                {/*<Grid item xs={6} container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Costi magazzino</FormLabel>
                    </Grid>
                    <Grid item xs={12} container key="cost" spacing={1}>
                        <Grid item xs={6}>
                            <CostTextField
                                invalid={invalids.cost}
                                id="cost"
                                label="Standard"
                                element={element}
                                onInputChange={(id, v) => onElementOp(e => {
                                    e.cost = v;
                                    if(!e.discounted_cost) {
                                        e.discounted_cost = v;
                                    }
                                })}
                                baseId={baseId}
                            />
                        </Grid>
                        <Grid item xs={6} key="discounted_cost">
                            <CostTextField
                                id="discounted_cost"
                                label="Scontato"
                                element={element}
                                onInputChange={onInputChange}
                                baseId={baseId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Costi woocommerce</FormLabel>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={6} key="woocommerce_cost">
                            <CostTextField
                                invalid={invalids.woocommerce_cost}
                                id="woocommerce_cost"
                                label="Standard"
                                element={element}
                                onInputChange={(id, v) => onElementOp(e => {
                                    e.woocommerce_cost = v;
                                    if(!e.woocommerce_discounted_cost) {
                                        e.woocommerce_discounted_cost = v;
                                    }
                                })}
                                baseId={baseId}
                            />
                        </Grid>
                        <Grid item xs={6} key="woocommerce_discounted_cost">
                            <CostTextField
                                id="woocommerce_discounted_cost"
                                label="Scontato"
                                element={element}
                                onInputChange={onInputChange}
                                baseId={baseId}
                            />
                        </Grid>
                    </Grid>
                            </Grid>*/}
                <Grid item xs={12}>
                    <Button 
                        type="submit" 
                        data-testid={`${baseId}-submit`}
                        //disabled={!element.valid}
                        variant="outlined"
                        color="primary"
                        fullWidth
                    >
                        Conferma
                    </Button>
                </Grid>
            </Grid>
        </FormControl>;
    }
    return null;
};

export default wrapEdit({
    Component: inject(
        ServicesContext,
        ["product"],
        ProductDetailsEntityEditor
    ),
    ComponentProps: {},
    meta: ProductMeta,
    config: {
        noValidate: false,
        noSetOnInvalid: false
    }
})
