import React, {useState, memo} from "react";
import ProductCategoryEditor from "../../category/product-category-editor.component";
import ProductDetailsEditor from "../product-details-entity-editor.component";
import ProductImageEditor from "../../image/product-image-editor.component";
import ProductOverview from "../../entity-renderers/product-overview.component";
import ComponentsContext from "../../../../contexts/components.context";
import ServicesContext from "../../../../contexts/services.context";
import {HTMLCarousel as Carousel, CarouselService, CustomCarouselService, inject} from "js-react-components";
import {Grid, Container, Divider, ButtonGroup, Button, Tabs, Tab} from "@material-ui/core";
import WindowService from "../../../../services/auxiliary/window-service";
import SettingsContext from "../../../../contexts/settings.context";
import {wrap} from "../../../../domain/product";
import { makeComposer } from "../../../auxiliary/base-composer.component";
import {InlineTrigger, FabTrigger} from "../../../auxiliary/fab-trigger.component";
import VariantGenerationAttributesSelection from "./variant-generation/variant-generation-attribute-selection.component";
import LoadingComponent from "../../../auxiliary/loading.component";
import ProductCostVariantsContext from "../../../../contexts/product-cost-variants.context";
import moment from "moment";

const SectionSelector = memo(
    ({onSelect, selected, variable, hasVariants}) => <Tabs 
        value={selected} 
        onChange={(e, v) => onSelect(v)}
        variant="scrollable"
    >
        {([
            "Dati", "Costi", "Categorie", "Immagini", "Lista varianti", "Gen. varianti", "Statistiche"
        ]).concat(hasVariants ? [] : ["Caricamenti e resi"]).map((s, i) => <Tab 
            value={i}
            label={s}
            key={s}
        />)}
    </Tabs>,
    (p, n) => p.selected === n.selected
)

const Loader = () => <LoadingComponent size="3em" label="Caricamento..." />

const Component = inject(
    ComponentsContext,
    ["ProductVariant", "ProductStat", "ProductQuantityAlterationAlt", "ProductCost"],
    inject(
        ServicesContext,
        ["windowService", "alertService", "deleteDialogService"],
        inject(
            SettingsContext,
            ["productEditorWidth", "smallScreen"],
            /**
             * @param {{windowService: WindowService}}
             */
            ({
                element, onSave, noDetails, ProductVariant, ProductStat, ProductCost, alertService, loading,
                windowService, productEditorWidth, onOperation, ProductQuantityAlterationAlt, deleteDialogService,
                smallScreen
            }) => {
                const [section, setSection] = useState(0);
                const [vLoading, setLoading] = useState(false);
                const onSetSection = n => {
                    //carouselService.switch(n);
                    setSection(n);
                };
                const onVariantsGenerate = attrs => {
                    const p = onOperation("generateVariants", {product: element, attributes: attrs});
                    if(p instanceof Promise) {
                        console.log("generating variants");
                        setLoading(true);
                        p.then(() => {
                            alertService.show({
                                message: "Varianti generate.",
                                severity: "success"
                            })
                        })
                        .catch(mex => alert(mex))
                        .finally(() => setLoading(false));
                    }
                }
                const wrapped = element && wrap(element);
                return element ? <Container maxWidth={false} disableGutters style={{position: "relative"}}>
                    {loading && <Loader />}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ProductOverview product={element} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <SectionSelector 
                                    onSelect={onSetSection} 
                                    selected={section} 
                                    noDetails={noDetails}
                                    hasVariants={wrapped.is("variable")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {(section === 0) && <ProductDetailsEditor element={element} onSave={onSave} />}
                                {(section === 1) && <ProductCostVariantsContext.Provider value={{resetCosts: true}}>
                                    <ProductCost productId={element.id} />    
                                </ProductCostVariantsContext.Provider>}
                                {(section === 2) && <ProductCategoryEditor product={element} />}
                                {(section === 3) && <ProductImageEditor product={element} />}
                                {(section === 4) && <ProductVariant 
                                    defaultView="table" 
                                    defaultFilterAttribute="variable_product_id" 
                                    defaultFilterValue={element.id} 
                                    Composer={makeComposer({
                                        Adder: inject(
                                            ServicesContext,
                                            ["windowService"],
                                            ({windowService}) => smallScreen ? <FabTrigger 
                                                onClick={() => windowService.href(`/variable_product/${element.id}/variant/add`)} 
                                            /> : <InlineTrigger 
                                                onClick={() => windowService.href(`/variable_product/${element.id}/variant/add`)} 
                                            />
                                        )
                                    })}
                                />}
                                {(section === 5) && <VariantGenerationAttributesSelection
                                    noHeader
                                    baseProduct={element}
                                    loading={vLoading}
                                    onGenerate={attrs => deleteDialogService.show({
                                        message: element.variants_number ? "Effettuare la generazione con delle varianti già esistenti è sconsigliato, poichè potrebbe causare errori nella sincronizzazione con Woocommerce. Procedere ugualmente?" : "Procedere con la generazione delle varianti?",
                                        onConfirm: () => onVariantsGenerate(attrs)
                                    })}
                                />}
                                {(section === 6) && <ProductStat
                                    filterAttribute="product_id"
                                    filterValue={element.id}
                                    CollectionRendererProps={{product: element}}
                                />}
                                {(section === 7) && !wrapped.is("variable") && <ProductQuantityAlterationAlt
                                    productId={element.id}
                                    defaultView="table"
                                    ComposerProps={{
                                        EditRendererProps: {
                                            element: {product_id: element.id, date: moment().format("YYYY-MM-DD")}
                                        }
                                    }}
                                />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container> : null;
            }
        )
    )
);

export default Component;
