import React, {useEffect, useState} from "react";
import ComponentsContext from "../../../contexts/components.context";
import ServicesContext from "../../../contexts/services.context";
import {inject} from "js-react-components";
import {EntityService, HttpOperation, observe, remove, notify, ObserverPool} from "js-generic-utilities";
import {Container, Grid, Typography, Box, Button, Dialog, DialogTitle, DialogContent} from "@material-ui/core";
import {Product} from "../../../domain/product";
import CategorySearchContext from "../../category/search-component/context/category-search-context";

const aux = cats => cats ? cats.map(c => ({id: c.category_id})) : [];

const Component = inject(
    ComponentsContext,
    ["ProductCategory", "CategorySearch"],
    inject(
        ServicesContext, 
        ["productCategory", "productCategoryComponent"],
        /**
         * @param {{
         *      ProductCategory: Function,
         *      CategorySearch: Function,
         *      productCategory: EntityService<HttpOperation>,
         *      productCategoryComponent: ObserverPool<"refresh">,
         *      product: Product
         * }} param0
         */
        ({ProductCategory, CategorySearch, productCategory, productCategoryComponent, product}) => {

            const [cats, setCats] = useState(null);
            const [shown, setShown] = useState(false);

            const toggleShown = () => setShown(s => !s)

            useEffect(() => {
                console.log("setting observer on productCategory");
                if(cats === null) {
                    observe(productCategory).on("get").with(stuff => {
                        console.log(stuff);
                        setCats(stuff);
                    });
                    setCats([]);
                    return () => remove(setCats).from(productCategory).on("get").do();
                }
            }, []);

            const onCategorySelect = c => {
                productCategory
                    .serve("save", {product_id: product.id, category_id: c.id})
                    .then(() => {
                        console.log("notifying of refresh");
                        return notify(productCategoryComponent).of("refresh").withNull();
                    })
                    .catch(mex => console.log(mex))
                    .finally(() => setShown(false))
            }

            return (cats !== null) ? <CategorySearchContext.Provider value={{selectedCategories: aux(cats)}}>
                <Container maxWidth={false} disableGutters style={{position: "relative"}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} component="section">
                            {/*<Typography component="header" variant="h4" gutterBottom>
                                Categorie attuali (<Typography component="span" data-testid="product-category-editor-cats">{cats && cats.length}</Typography> selezionate)
        </Typography>*/}
                            <Box component="article">
                                <ProductCategory productId={product.id} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} component="section" alignItems="center" justify="space-between">
                            <Button 
                                type="button" 
                                fullWidth 
                                onClick={toggleShown} 
                                variant="outlined" 
                                color="primary"
                            >
                                Aggiungi categoria
                            </Button>
                        </Grid>
                        <Dialog maxWidth="md" onBackdropClick={toggleShown} open={shown}>
                                <DialogTitle disableTypography>
                                    <Typography component="header" variant="h2">Aggiunta categoria</Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <CategorySearch onSelect={onCategorySelect} />
                                </DialogContent>
                            </Dialog>
                    </Grid>
                </Container>
            </CategorySearchContext.Provider> : null;
        }
    )
);

export default Component;