import React from "react";
import {
    ReactComponent, 
    ComposerProps, 
    EntityOperation,
    EntityRendererProps,
    PaginatorProps,
    FiltererProps,
    inject
} 
from "js-react-components";
import {EntityService, HttpOperation, notify} from "js-generic-utilities";
import BasketMeta, {BasketWrapper, Basket, BasketAttributes, BasketIndices, BasketSorters, Basketviews, wrap} from "../../domain/basket";
import BasketProductList from "./entity-renderer/basket-product-list-renderer.component";
import {
    render, 
    EntityBaseComponentBuilder, 
    makeSearchBar, 
    makePaginator, 
    makeGridRenderer,
    ListCollectionRenderer
} from "js-react-components";
import LoadingComponent from "../auxiliary/loading.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import BasketSideViewService from "./side-view/basket-side-view.service";
import servicesContext from "../../contexts/services.context";
import BasketEditor from "./entity-editor/basket-editor.component";
import BasketAdder from "./entity-editor/basket-adder.component";
import EditSuccess from "../auxiliary/edit-success.component";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import {ShoppingBasket} from "@material-ui/icons";
import {texts} from "../../constants";
import {makeDateFilterer} from "../auxiliary/filterers/simple-date-filterer.component";
import BasketProductListEditor from "./entity-editor/basket-product-list-editor.component";
import { Box, Select, TextField } from "@material-ui/core";

const BaseBasketComposer = makeComposer({
    EditRenderer: BasketAdder, 
    config: {
        filterLabel: "Filtraggio per data"
    }
});
const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const Filterer = /*makeDateFilterer({
    attribute: "date", 
    resolution: "days", 
    testID: "basket-filterer",
    TypographyProps: {
        component: "span",
        variant: "h4",
        style: {
            color: "black"
        }
    }
});*/ ({attribute, value, onChange, onClear}) => (!attribute || (attribute === "status")) && <Box data-testid="basket-filterer-wrapper" style={{width: "100%"}}>
    <Select
        native
        value={value || "all"}
        variant="outlined"
        fullWidth
        onChange={evt => {
            const v = evt.target.value;
            (v === "all") ? onClear() : onChange({attribute: "status", value: v});
        }}
    >
        <option value="all">Tutti i carrelli</option>
        <option value="open">Carrelli aperti</option>
        <option value="reserved">Carrelli sospesi</option>
        <option value="paid">Carrelli chiusi</option>
    </Select>
</Box>

/**
 * @typedef {EntityBaseComponentBuilder<"refresh", Basket, Basketviews, BasketIndices, BasketSorters, BasketAttributes>} BasketComponentBuilder
 */

const BasketProductListWrapped = inject(
    servicesContext,
    ["basketSideView", "basket"],
    (props) => <BasketProductList {...props} onAfterSave={() => {
        props.basketSideView.reload();
        notify(props.basket).of("refresh").withNull();
    }} />
);

const wrapOptions = {
    avatarSize: 100,
    title: texts.missing_elements_title("carrello"),
    body: texts.missing_elements_body,
    Icon: ShoppingBasket
};

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      composer: ReactComponent<ComposerProps>,
 *      operations?: EntityOperation<Product>[],
 *      EntityRenderer: ReactComponent<EntityRendererProps<Basket>>,
 *      Paginator: ReactComponent<PaginatorProps>,
 *      itemsPerPage?: number,
 *      defaultView?: "list" | "grid",
 *      filters?: {
 *          attribute: string,
 *          value: string
 *      },
 *      alertService: AlertService,
 *      basketSideViewService: BasketSideViewService
 * }} param0 
 */
const makeBasketBaseComponent = ({
    service, composer, operations, EntityRenderer, ListEntityRenderer, windowService, alertService,
    Paginator, itemsPerPage, defaultView, filters, Editor, basketSideViewService, componentService,
    onAfterDelete, GridRenderer, deleteDialogService
}) => {
    
    /**
     * @type {BasketComponentBuilder}
     */
    let builder = render(service);

    const DefaultGridRenderer = makeGridRenderer({
        spacing: 3, 
        elementsPerRow: {xs: 6, sm: 4}
    });

    builder.withMeta(BasketMeta)
        .withGrid({
            Entity: EntityRenderer,
            Collection: wrapRenderer(GridRenderer || DefaultGridRenderer, wrapOptions)
        })
        .withList({
            Entity: ListEntityRenderer || BasketProductListWrapped,
            Collection: wrapRenderer(ListCollectionRenderer, wrapOptions)
        })
        .configure({
            editing: false,
            adding: false,
            deleting: false
        })
        .withBounds({
            start: 0, end: itemsPerPage || 8
        })
        .withOperations([
            {
                name: "setActive",
                label: "setActive",
                handler: e => basketSideViewService.refresh(e)
            },
            {
                name: "close",
                label: "close",
                handler: (e, {onSave}) => {
                    e = (new BasketWrapper({...e})).close().get();
                    return onSave(e);
                }
            },
            {
                name: "reserve",
                label: "reserve",
                handler: (e, {onSave}) => {
                    e = (new BasketWrapper({...e})).reserve().get();
                    return onSave(e);
                }
            },
            {
                name: "delete",
                handler: (e, {onDelete}) => deleteDialogService.show({
                    message: `Confermare la cancellazione del carrello?`, 
                    onConfirm: () => onDelete(e)
                })
            }
        ])
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withDefaultView(defaultView || "list")
        .withComponents({
            Paginator: Paginator || makePaginator({}, "basket-paginator", {
                defaultItemsPerPage: itemsPerPage || 8,
                hideItemsPerPage: true,
                buttons: true,
                allowFirstLastPages: true,
                hideTotalItems: true
            }),
            Filterer: Filterer,
            Sorter: () => null,
            Loader: Loader,
            Alert: () => null
        });
    
    if(operations) {
        builder.withOperations(operations);
    }

    if(filters) {
        builder.withFilter(filters);
    }

    if(Editor) {
        builder.editEntityWith(Editor);
    } else {
        builder.editEntityWith(BasketProductListEditor);
    }

    if(componentService) {
        builder.withService(componentService);
    }
    
    builder.withCallbacks({
        onAfterSave: e => {
            const o = wrap(e).isEmpty();
            alertService.show({message: e.message || <EditSuccess 
                entity={e} 
                noLink
                entityName="basket" 
                subheader={o ? "Adesso è possibile aggiungere i prodotti." : ""}
            />, severity: !e.message ? "success" : "warning"});
            o && windowService.href(`/basket/${e.id}`);
        },
        onAfterDelete: e => {
            alertService.show({message: e.message || "Carrello cancellato.", severity: !e.message ? "success" : "warning"});
            onAfterDelete && onAfterDelete();
        },
        onError: e => alertService.show({
            message: e,
            severity: "error"
        })
    });
    
    return builder.compose(composer || BaseBasketComposer);
};

export default makeBasketBaseComponent;