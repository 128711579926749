import React from "react";

import {
  Box,
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  ListItemAvatar,
  Avatar,
  IconButton
} from "@material-ui/core";
import { Delete, FolderOpen, Edit } from "@material-ui/icons";
import { inject } from "js-react-components";
import settingsContext from "../../../contexts/settings.context";

const useStyles = makeStyles((theme) => ({
  lineBox: {
    width: "50px",
    marginRight: "1em",
    height: "1px",
    backgroundColor: "rgb(111, 111, 111)"
  },
  valueListItem: {
    background: "white"
  }
}));

const AttributeValueListRenderer = inject(
  settingsContext,
  ["smallScreen"],
  ({ element, onSelect, onDelete, onOperation, smallScreen }) => {
    const classes = useStyles();
    const ButtonComponent = smallScreen ? IconButton : Button;
    return element && (
      <ListItem 
        disableGutters 
        className={classes.valueListItem} 
        data-testid={`attribute-value-list-element-${element.id}`}
      >
        {/*<Box className={classes.lineBox} />*/}
        <ListItemAvatar>
          <Avatar>
            {element.value && element.value.substring(0,1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={element.value} secondary={element.full_value} />
        <ListItemSecondaryAction>
          <ButtonComponent 
            color="primary" 
            onClick={() => onSelect(element)} 
            data-testid={`attribute-value-list-element-${element.id}-edit`}
          >
            {smallScreen ? <Edit /> : <Typography component="p" variant="caption">
              Modifica
            </Typography>}
          </ButtonComponent>
          <ButtonComponent 
            color="secondary" 
            onClick={() => onDelete(element)}
            data-testid={`attribute-value-list-element-${element.id}-delete`}
          >
            {smallScreen ? <Delete /> : <Typography component="p" variant="caption">
              Cancella
            </Typography>}
          </ButtonComponent>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
)

export default AttributeValueListRenderer;