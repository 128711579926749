import React from "react";
import {
    EntityBaseComponentBuilder, 
    render, 
    ComposerProps, 
    ReactComponent, 
    makeGridRenderer,
    EntityRendererProps,
    makePaginator
} from "js-react-components";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import ProductImageMeta, {ProductImageAttributes, ProductImage, ProductImageIndices, ProductImageSorters} from "../../domain/product-image";
import ImageAsyncRenderer from "../image/entity-renderers/image-async-renderer.component";
import LoadingComponent from "../auxiliary/loading.component";
import { Box } from "@material-ui/core";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import { Image } from "@material-ui/icons";
import { texts } from "../../constants";

/**
 * @type {ReactComponent<EntityRendererProps<ProductImage>>} 
 */
const GridRenderer = ({onDelete, element}) => {
    return (typeof(element.product_id) === "number") ? <ImageAsyncRenderer
        key={`${element.product_id}-${element.image_id}`}
        testId={`product-${element.product_id}-image-${element.image_id}`}
        onRemove={() => onDelete(element)} 
        id={element.image_id}
    /> : null;
};

/**
 * @param {ComposerProps} param0 
 */
const Composer = ({collection, loader}) => <Box position="relative">
    {collection}
    {loader}
</Box>;
const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;

/**
 * @typedef {"grid"} ProductImageViews
 * @typedef {EntityBaseComponentBuilder<"refresh", ProductImage, ProductImageViews, ProductImageIndices, ProductImageSorters, ProductImageAttributes>} ProductImageBaseComponentBuilder
 * @param {{
 *      componentService: ObserverPool<"refresh">,
 *      service: EntityService<HttpOperation>
 * }} param0 
 */
export default function makeComponent({service, componentService, alertService}) {
    /**
     * @type {ProductImageBaseComponentBuilder}
     */
    let builder = render(service);

    const GridCollectionRenderer = makeGridRenderer({
        spacing: 2,
        elementsPerRow: {xs: 12, sm: 3}
    });

    builder.withMeta(ProductImageMeta)
        .withGrid({
            Collection: wrapRenderer(GridCollectionRenderer, {
                title: "Nessuna immagine selezionata", 
                body: "Puoi aggiungerne una mediante l'apposito pulsante",
                Icon: Image,
                avatarSize: 100
            }),
            Entity: GridRenderer
        })
        .withFilter({attribute: "product_id"})
        .withService(componentService)
        .withDefaultView("grid")
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withComponents({
            Alert: () => null,
            Loader: Loader,
            Filterer: () => null,
            Sorter: () => null,
            Paginator: makePaginator({}, "product-image-paginator", {defaultItemsPerPage: 99999})
        })
        .withBounds({start: 0, end: 99999})
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: e.message || "Immagine aggiunta.",
                severity: e.message ? "warning" : "success"
            })
        });

    const Component = builder.compose(Composer);
    return ({productId}) => <Component filterValue={productId} />;
}
