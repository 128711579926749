import {render} from "react-dom";
import {initServices, initComponents, initRequestManager, configStuff} from "./init-functions";
import BaseComponents from "./contexts/components.theme";
import {MuiThemeProvider} from "@material-ui/core";
import renderRoot from "./render-root";
import theme from "./mui-theme";
import smallTheme from "./small-mui-theme";
import {debug, baseUrl, roleGetter, credentialsValidator, authEndpoints} from "./constants";
import {initAuthComponents} from "react-auth-components";
import {notify} from "js-generic-utilities";
import {cookieGetter} from "./utils/cookie-getter";
import "./styles.css";

if(window.navigator.userAgent.indexOf("Edge") > -1) {
  Promise.prototype.finally = Promise.prototype.finally || {
    finally (fn) {
      const onFinally = callback => Promise.resolve(fn()).then(callback);
      return this.then(
        result => onFinally(() => result),
        reason => onFinally(() => Promise.reject(reason))
      );
    }
  }.finally;
}

const toRender = "main";

configStuff();

const rootElement = document.getElementById("root");

/* initialization */
const fakeRequestManager = initRequestManager(true);
const requestManager = initRequestManager(false, baseUrl, {});

const username = cookieGetter(document).get("username");
const token = cookieGetter(document).get("workshop_token");
const refresh = cookieGetter(document).get("workshop_refresh");
/*
const username = "a";
const refresh = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.MTYxNDQ0Mzg5Mw.RkKpqLAF6wr1V5NEp90ZjkyQ4PA8R3kDvAVTumhLyfs";
const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6InNhbXVlbGUudGFtYnVyaUBnbWFpbC5jb20iLCJyb2xlIjo0LCJ0aW1lc3RhbXAiOjE2MTQ0NDM4OTMsInR0bCI6MTAwMDAwMH0.Rql80BFuw1vfsnMwM-Csej7bbmxFfz4RlluVE7oEJ_U";
*/
/* main routes rendering */
initAuthComponents({
  roleGetter,
  endpoints: authEndpoints,
  validator: credentialsValidator,
  requestManager,
  auth: {token, username, refresh}
})(
  ({LoginPanel, Gate, Auth, authService, authRequestManagerWrapper}) => {

    /*token && notify(authService._pool).of("refreshToken").with({
      token: token, username: username, refresh: refresh, role: "admin"
    });*/

    let services = initServices(debug ? fakeRequestManager : authRequestManagerWrapper);
    let components = initComponents(services);

    components = {
      ...components,
      LoginPanel,
      Gate,
      Auth
    };

    services = {
      ...services,
      authService
    };

    render(
      <MuiThemeProvider theme={(window.innerWidth < 800) ? smallTheme : theme}>
        {renderRoot(toRender, {BaseComponents, services, components, requestManager: authRequestManagerWrapper})}
      </MuiThemeProvider>,
      rootElement
    );

    /*notify(authService._pool).of("refreshToken").with({
      token: "token", username: "username", refresh: "refresh", role: "admin"
    });*/
  }
);