import React from "react";
import {EntityRendererProps, ReactComponent, inject} from "js-react-components";
import {wrap, Product} from "../../../domain/product";
import {
    ListItem, ListItemAvatar, ListItemText, 
    ListItemSecondaryAction, Avatar, makeStyles, 
    Chip, Badge, Tooltip, Typography, Button
} from "@material-ui/core";
import ProductButtonGroup from "./auxiliary/product-button-group.component";
import { imgPlaceholder } from "../../../constants";
import {shorten} from "../../../utils/misc-utils-functions";
import settingsContext from "../../../contexts/settings.context";
import clsx from "clsx";
import { Link } from "react-router-dom";
import NameShortener from "../../auxiliary/name-shortener.component";

const useStyles = makeStyles(t => ({
    img: {
        display: "block",
        maxWidth: "calc(min(100%, 100px))"
    },
    opaque: {
        opacity: 0.5
    }
}));

/**
 * @type {ReactComponent<EntityRendererProps<Product>>}
 */
const ProductListRenderer = inject(
    settingsContext,
    ["smallScreen"],
    ({
        element, onSelect, onDelete, noDelete, onOperation, select, smallScreen, selectable
    }) => {
        const classes = useStyles();
        const wrapped = element && wrap(element);
        const isSelectable = (typeof(selectable) === "function") ? selectable(wrapped) : true;
        return element && <ListItem 
            key={element.id} 
            data-testid={`product-list-element-${element.id}`}
            className={clsx([!isSelectable && classes.opaque])}
        >
            <ListItemAvatar>
                <Tooltip
                    arrow
                    placement="top"
                    title={<Typography component="span" variant="body2">
                        {!wrapped.isRiskyStock() ? "Stock sufficiente" : (wrapped.isOver() ? "Esaurito" : "In esaurimento")}
                    </Typography>}
                >
                    <Badge
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        badgeContent={<Chip 
                            label={element.quantity} 
                            size="small"
                            style={{
                                color: "white", 
                                fontWeight: "bold", 
                                backgroundColor: !wrapped.isRiskyStock() ? "rgb(32, 132, 232)" : (wrapped.isOver() ? "red" : "orange")
                            }} 
                        />}
                    >
                        <Avatar variant="square">
                            <img 
                                src={element.image || imgPlaceholder} 
                                alt={element.name} 
                                className={classes.img} 
                            />
                        </Avatar>
                    </Badge>
                </Tooltip>
            </ListItemAvatar>
            <ListItemText 
                disableTypography
                primary={<NameShortener TypographyProps={{style: {marginLeft: "0.69em"}}} length={35} text={element.name} />} 
                secondary={<Typography variant="body2" component="p" style={{marginLeft: "0.75em"}}>{wrapped.printType()}, codice {element.code}</Typography>}
            />
            <ListItemSecondaryAction>
                {!smallScreen ? <ProductButtonGroup
                    link={!select}
                    noDelete={noDelete}
                    select={select}
                    selectable={isSelectable}
                    element={element}
                    onDelete={onDelete}
                    onOperation={onOperation}
                /> : <Link to={`/product/${element.id}`}>
                    <Button variant="outlined" color="primary">
                        Modifica
                    </Button>
                </Link>}
            </ListItemSecondaryAction>
        </ListItem>;
    }
);

export function makeProductListRenderer({noDelete, select, selectable}) {
    return (props) => <ProductListRenderer 
        {...props} 
        noDelete={noDelete} 
        select={select} 
        selectable={selectable} 
    />;
}

export default ProductListRenderer;
