import React, { Fragment } from "react";
import {EntityRendererProps, ReactComponent, inject} from "js-react-components";
import {Product, wrap} from "../../../domain/product";
import {
    ListItem, ListItemAvatar, ListItemText, 
    ListItemSecondaryAction, Avatar, makeStyles, 
    Chip, Badge, TableRow, TableCell, Typography, Box
} from "@material-ui/core";
import ProductButtonGroup from "./auxiliary/product-button-group.component";
import componentsContext from "../../../contexts/components.context";
import VariantAttributesGrid from "../variant/variant-attributes-grid.component";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import servicesContext from "../../../contexts/services.context";
import ChipRenderer from "../variant/variant-attribute-chip-renderer.component";
import { imgPlaceholder } from "../../../constants";
import ProductCostPrinter from "./auxiliary/product-cost-printer.component";
import NameShortener from "../../auxiliary/name-shortener.component";

const useStyles = makeStyles(t => ({
    img: {
        display: "block",
        maxWidth: "calc(min(100%, 100px))"
    }
}));

/**
 * @type {ReactComponent<EntityRendererProps<Product>>}
 */
const ProductTableRenderer = inject(
    componentsContext,
    ["AttributeValue"],
    inject(
        servicesContext,
        ["attributeValue"],
        ({
            element, 
            onSelect, 
            onDelete, 
            noDelete, 
            onOperation, 
            select, 
            attributeValue
        }) => {
            const classes = useStyles();
            const avatar = <Avatar>
                <img 
                    src={element.image || imgPlaceholder} 
                    className={classes.img} 
                    alt={element.name} 
                />
            </Avatar>;
            return element && <Fragment 
                key={element.id} 
                data-testid={`product-table-element-${element.id}`}
            >
                <TableCell key="name">
                    <Box display="flex" alignItems="center">
                        <Box marginRight="0.5em">
                            {Boolean(element.woocommerce_id) ? <Badge color="primary" badgeContent="W">{avatar}</Badge> : avatar}
                        </Box>
                        <Box>
                            <NameShortener text={element.name} length={99999} TypographyProps={{gutterBottom: true, component: "p", variant: "body1"}} />
                            <Typography component="p" variant="body2" style={{color: "rgb(134, 134, 134)"}}>
                                Codice {element.code}
                            </Typography>    
                        </Box>
                    </Box>
                </TableCell>
                <TableCell key="stock">
                    <Typography component="p" variant="body1">
                        <Chip 
                            variant="default" 
                            style={{backgroundColor: wrap(element).getStockColor(), color: "white"}}
                            label={element.quantity} 
                        />   
                    </Typography>    
                </TableCell>
                <TableCell key="costs">
                    <Typography component="p" variant="body1">
                        <ProductCostPrinter product={element} />    
                    </Typography>    
                </TableCell>
                <TableCell key="actions">
                    <ProductButtonGroup
                        link
                        select
                        selectable
                        noDelete={noDelete}
                        element={element}
                        onDelete={onDelete}
                        onOperation={onOperation}
                        onSelect={onSelect}
                        selectLabel="Mod. rapida"
                    />
                </TableCell>
            </Fragment>;
        }
    )
);

export function makeProductTableRenderer({noDelete, select}) {
    return (props) => <ProductTableRenderer {...props} noDelete={noDelete} select={select} />;
}

export default ProductTableRenderer;