import {Label, Person} from "@material-ui/icons";

const debug = false;
/*
const baseUrl = "http://localhost/singer-workshop-v2/server/public";
const imgPlaceholder = "http://localhost/singer-workshop-v2/server/img/placeholder.jpg";
*/
const baseUrl = "https://singer.italweb.agency/gestionale-v2/api/public";
const imgPlaceholder = "https://singer.italweb.agency/gestionale-v2/api/img/placeholder.jpg";

const IconComponents = {
    category: Label,
    customer: Person,
    attribute: Label
}

const texts = {
    missing_elements_title: (name, a) => `Nessun${a?"a":""} ${name} presente.`,
    missing_elements_body: "Per aggiungere un elemento premi l'apposito pulsante"
};

/* fake authentication tokens */

const token = "";
const refresh = "";

/* authentication elements */

const roleGetter = c => "admin";
const credentialsValidator = () => true;
const authEndpoints = {
    
    auth: "https://singer.italweb.agency/gestionale-v2/api/public/auth/token",
    refresh: "https:/singer.italweb.agency/gestionale-v2/api/public/auth/token/refresh",
    
    /*
    auth: "http://localhost/singer-workshop-v2/server/public/auth/token",
    refresh: "http://localhost/singer-workshop-v2/server/public/auth/token/refresh"*/
    
};

export {
    debug, 
    baseUrl, 
    imgPlaceholder, 
    IconComponents, 
    texts, 
    token, 
    refresh, 
    roleGetter, 
    credentialsValidator, 
    authEndpoints
};