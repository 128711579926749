import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeBasketService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => (e.filterAttribute === "customer_id") ? `/customer/${e.filterValue}/cart` : `/cart`}).build(),
        handle("count").with({method: "GET", path: (d, e) => (e.filterAttribute === "customer_id") ? `/customer/${e.filterValue}/cart/count` : `/cart/count`}).build(),
        handle("save").with({method: "POST", path: e => `/customer/${e.customer_id}/cart`}).build(),
        handle("update").with({method: "POST", path: e => `/customer/${e.customer_id}/cart/${e.id}`}).build(),
        handle("delete").with({method: "DELETE", path: e => `/customer/${e.customer_id}/cart/${e.id}`}).build()
    ];

    return serve(handlers).using(requestManager).build();
}