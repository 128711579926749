import React, { Fragment, useState } from "react";
import {inject} from "js-react-components";
import ComponentsContext from "../contexts/components.context";
import ServicesContext from "../contexts/services.context";
import {Container, Grid, Card, CardHeader, CardContent, CardActionArea, CardActions, Avatar, IconButton, Button, Box, Divider, makeStyles, Typography, Tabs, Tab, Switch, Select, MenuItem, Fab} from "@material-ui/core";
import {ShoppingBasket, AccountBox, Share, Add, Warning} from "@material-ui/icons";
import SettingsContext from "../contexts/settings.context";
import AdderWrapper from "../components/auxiliary/editors/adder-wrapper.component";
import BasketAdder from "./basket/entity-editor/basket-adder.component";
import {wrap} from "../domain/product";

const useStyles = makeStyles(theme => ({
    header: {
        textTransform: "uppercase",
        color: "grey",
        fontWeight: "bold"
    }
}));

const StuffComposer = ({collection, searchBar, paginator}) => <Grid container>
    <Grid item xs={12}>
        {searchBar}
    </Grid>
    <Grid item xs={12}>
        {collection}
    </Grid>
    <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
            {paginator}
        </Box>
    </Grid>
</Grid>;

const titleProps = {
    variant: "h4",
    component: "header",
    style: {
        fontWeight: "bold",
        color: "grey"
    }
};

const SingerDashboard = inject(
    ComponentsContext,
    ["Basket", "Product", "BasketStat", "AlertDashboard"],
    inject(
        ServicesContext,
        ["windowService"],
        inject(
            SettingsContext,
            ["smallScreen"],
            ({Basket, Product, windowService, BasketStat, AlertDashboard, smallScreen}) => {
                const [tab, setTab] = useState(0);
                const [filter, setFilter] = useState("open");
                return <Container 
                    maxWidth={false} 
                    disableGutters
                    component="article"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                                <Tab label="Pagina principale" value={0} />
                                <Tab label="Statistiche" value={1} />
                            </Tabs>
                        </Grid>
                        {(tab === 0) && <Fragment>
                            <Grid item xs={12}>
                                <Box position="relative" component="section">
                                    <Basket 
                                        sortAttribute="date" 
                                        sortDirection="desc" 
                                        filterAttribute={(filter !== "all") ? "status" : undefined}
                                        key={filter}
                                        filterValue={(filter !== "all") ? filter : undefined}
                                        Composer={({collection, searchBar, paginator, onSave, filterer, loader}) => <Fragment>
                                            {loader}
                                            <Card id="dashboard-baskets">
                                                <CardHeader
                                                    title="Carrelli"
                                                    subheader={!smallScreen && "aperti e sospesi"}
                                                    titleTypographyProps={titleProps}
                                                    avatar={<Avatar><ShoppingBasket /></Avatar>}
                                                    action={<Box display="flex" alignItems="center" width="100%">
                                                        {!smallScreen && <Box marginRight="1em">
                                                            {paginator}
                                                        </Box>}
                                                        <Box marginRight="1em">
                                                            <Select 
                                                                fullWidth
                                                                variant="outlined"
                                                                label="Tipo carrelli"
                                                                onChange={evt => setFilter(evt.target.value)} 
                                                                value={filter}
                                                            >
                                                                <MenuItem value="open" label="open" key="open">Aperti</MenuItem>
                                                                <MenuItem value="reserved" label="reserved" key="reserved">Sospesi</MenuItem>
                                                                <MenuItem value="all" label="all" key="all">Tutti</MenuItem>
                                                            </Select>
                                                        </Box>
                                                        <AdderWrapper 
                                                            onSave={onSave} 
                                                            Editor={BasketAdder} 
                                                            Trigger={({onClick}) => smallScreen ? <Fab onClick={onClick} color="primary" variant="round">
                                                                <Add />
                                                            </Fab> : <Button onClick={onClick} variant="contained" color="primary">
                                                                Aggiungi carrello
                                                            </Button>} 
                                                        />
                                                    </Box>}
                                                />
                                                <CardContent>
                                                    <StuffComposer 
                                                        collection={collection} 
                                                        searchBar={filterer}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Fragment>}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box position="relative" component="section">
                                    <Product
                                        sortAttribute="quantity"
                                        sortDirection="asc"
                                        defaultView="list"
                                        Composer={({searchBar, collection, paginator, loader, filterer}) => <Fragment>
                                            {loader}
                                            <Card>
                                                <CardHeader
                                                    title="Prodotti"
                                                    titleTypographyProps={titleProps}
                                                    subheader={!smallScreen && "in ordine di esaurimento"}
                                                    avatar={<Avatar>
                                                        <AccountBox />
                                                    </Avatar>}
                                                    action={smallScreen ? <Fab 
                                                        color="primary" 
                                                        variant="round" 
                                                        onClick={() => windowService.href("/product/add")}
                                                    >
                                                        <Add />
                                                    </Fab> : <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        onClick={() => windowService.href("/product/add")}
                                                    >
                                                        Aggiungi prodotto
                                                    </Button>}
                                                />
                                                <CardContent>
                                                    <StuffComposer 
                                                        collection={collection} 
                                                        searchBar={filterer} 
                                                        paginator={paginator} 
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Fragment>}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box position="relative" component="section">
                                    <AlertDashboard 
                                        sortAttribute="created_at" 
                                        sortDirection="desc" 
                                        key="alert"
                                        Composer={({collection, searchBar, paginator, onSave, filterer, loader}) => <Fragment>
                                            {loader}
                                            <Card>
                                                <CardHeader
                                                    title="Notifiche"
                                                    subheader="in ordine di data"
                                                    titleTypographyProps={titleProps}
                                                    action={filterer}
                                                    avatar={<Avatar><Warning /></Avatar>}
                                                />
                                                <CardContent>
                                                    <StuffComposer 
                                                        collection={collection} 
                                                        paginator={paginator} 
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Fragment>}
                                    />
                                </Box>
                            </Grid>
                        </Fragment>}
                        {(tab === 1) && <Grid item xs={12}>
                            <BasketStat />
                        </Grid>}
                    </Grid>
                </Container>;
            }
        )
    )
);

export default SingerDashboard;