import {handle, serve, EntityServiceRepository} from "js-generic-utilities";
import endpoints from "../endpoints";
import { getServiceArgs } from "../../utils/misc-utils-functions";

const getEndpointByType = (t, data) => {
    switch(t) {
        case "variable":
            return "/variable_product";
        case "variant":
            return `/variable_product/${data.variable_product_id}/variant`;
        default:
            return "/product";
    }
};

export function makeProductService({requestManager, costService}) {
    const handlers = [
        handle("get").with({
            method: "GET", 
            path: "/product"
        }).build(),
        handle("count").with({
            method: "GET", 
            path: "/product/count"
        }).build(),
        handle("save").with({
            method: "POST", 
            path: (data) => getEndpointByType(data.type, data),
        }).build(),
        handle("update").with({
            method: "POST", 
            path: (data) => getEndpointByType(data.type, data) + `/${data.id}`
        }).build(),
        handle("delete").with({
            method: "DELETE", 
            path: (e) => `${getEndpointByType(e.type, e)}/${e.id}`
        }).build(),
        handle("generate_code").with({
            method: "GET", 
            path: "/product/code"
        }).build(),
        handle("generate_variants").with({
            method: "POST", 
            path: ({product}) => `/variable_product/${product.id}/variant/generation`
        }).build()
    ];

    return serve(handlers).using(requestManager).build();
}

export function makeVariableProductService({requestManager}) {
    let service = EntityServiceRepository.http(getServiceArgs({
        requestManager, 
        endpoint: endpoints.variableProduct
    }));
    service._handlers.push(handle("generate_variants").with({
        method: "POST", 
        path: ({product}) => `/variable_product/${product.id}/variant/generation`
    }).build());
    return service;
}