import React from "react";
import {Category} from "../../../domain/category";
import {EntityRendererProps, inject, ReactComponent} from "js-react-components";
import {
  Card,
  CardHeader,
  CardActionArea,
  Avatar,
  Button,
  ButtonGroup,
  CardActions,
  Badge
} from "@material-ui/core";
import { LocalOffer } from "@material-ui/icons";
import servicesContext from "../../../contexts/services.context";

/**
 * @type {ReactComponent<EntityRendererProps<Category>>}
 */
const CategoryCard = inject(
  servicesContext,
  ["windowService"],
  ({ onDelete, onSelect, onOperation, element, deleteOnly, useOnDelete, windowService }) => {
    const avatar = <Avatar>
      <LocalOffer />
    </Avatar>;
    return element && (
      <Card data-testid={`category-${element.id}-card`}>
        <CardHeader
          avatar={Boolean(element.woocommerce_id) ? <Badge badgeContent="W" color="primary">{avatar}</Badge> : avatar}
          title={element.name}
          titleTypographyProps={{
            "data-testid": `category-${element.id}-card-name`
          }}
          subheader={`${element.products_num || "-"} prodotti associati`}
          subheaderTypographyProps={{
            "data-testid": `category-${element.id}-card-products`
          }}
        />
        <CardActionArea>
          <CardActions>
            <ButtonGroup fullWidth>
              {!deleteOnly && <Button 
                variant="text" 
                color="primary" 
                onClick={() => onSelect(element)}
                data-testid={`category-${element.id}-card-edit`}
              >
                Modifica
              </Button>}
              <Button 
                variant="text" 
                color="primary" 
                onClick={() => useOnDelete ? onDelete(element) : onOperation("delete", element)}
                data-testid={`category-${element.id}-card-delete`}
              >
                Cancella
              </Button>
              {!deleteOnly && <Button 
                variant="text" 
                color="primary" 
                onClick={() => windowService.href(`category/${element.id}/products`)}
              >
                Prodotti
              </Button>}
            </ButtonGroup>
          </CardActions>
        </CardActionArea>
      </Card>
    );
  }
);

export default CategoryCard;