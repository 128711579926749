import ComponentsContext from "../../../contexts/components.context";
import {inject} from "js-react-components";
import { Box, makeStyles, Collapse, withStyles} from "@material-ui/core";
import {Fragment, useState} from "react";
import {Basket, wrap} from "../../../domain/basket";
import SettingsContext from "../../../contexts/settings.context";
import DefaultBasketRenderer from "./auxiliary/default-basket-renderer.component";
import BasketProductListInnerRenderer from "./auxiliary/basket-product-list-inner-renderer.component";
import ClosedBasketContext from "../contexts/closed-basket.context";

const useStyles = makeStyles(t => ({
    img: {
        display: "block",
        maxWidth: "calc(min(100%, 100px))"
    },
    basketTooltip: {
        cursor: "help"
    },
    open: {
        backgroundColor: "red"
    },
    closed: {
        backgroundColor: "green"
    },
    paid: {
        backgroundColor: "green"
    },
    reserved: {
        backgroundColor: "orange"
    },
    listItem: {
        backgroundColor: "rgb(250, 250, 250)",
        borderRadius: "5px", 
        border: "1px solid rgb(222, 222, 222)",
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    lel: {
        border: "1px solid rgb(222, 222, 222)",
        borderTop: 0,
        marginBottom: "0.5em"
    }
}));

const Composer = inject(
    SettingsContext,
    ["basketProductAdderWidth"],
    withStyles({
        cost: {
            color: "green",
            fontWeight: "bold"
        }
    })(
        /**
         * @param {{basket: Basket}} param0 
         */
        ({
            collection, basket, classes, searchBar, loader, onSave, onBasketDelete, onBasketSelect,
            onOperation, BasketRenderer, defaultShown, basketProductAdderWidth, entities, innerList,
            onSetDiscount
        }) => {
            const [shown, setShown] = useState(defaultShown);
            const classes2 = useStyles();
            BasketRenderer = BasketRenderer || DefaultBasketRenderer;
            return <Fragment key={basket.id}>
                {!innerList ? <BasketRenderer 
                    basket={basket}
                    onShow={() => onBasketSelect(basket)}
                    onOperation={onOperation}
                    classes={classes}
                    onBasketDelete={onBasketDelete}
                    shown={shown}
                /> : <BasketProductListInnerRenderer
                    collection={collection}
                    basket={basket}
                    loader={loader}
                    onSave={onSave}
                    entities={entities}
                    basketProductAdderWidth={basketProductAdderWidth}
                    onClose={() => onOperation("close", basket)}
                    onReserve={() => onOperation("reserve", basket)}
                    onSetDiscount={onSetDiscount}
                />}
            </Fragment>;
        }
    )
);

const BasketProductList = inject(
    ComponentsContext,
    ["BasketProduct"],
    /**
     * 
     */
    ({
        BasketProduct, 
        element, 
        onOperation, 
        BasketRenderer, 
        defaultShown, 
        onAfterSave, 
        onAfterDelete, 
        onSelect, 
        onDelete,
        innerList,
        onSetDiscount
    }) => {
        const classes = useStyles();
        return element && <Box className={classes.listWrapper} key={element.id}>
            <ClosedBasketContext.Provider value={!wrap(element).isEditable()}>
                <BasketProduct
                    filterAttribute="cart_id" 
                    filterValue={element.id} 
                    sortAttribute="updated_at"
                    sortDirection="desc"
                    Composer={Composer}
                    ComposerProps={{
                        basket: element, 
                        classes, 
                        onOperation, 
                        BasketRenderer,
                        defaultShown,
                        innerList,
                        onBasketSelect: onSelect,
                        onBasketDelete: () => onOperation("delete", element),
                        onSetDiscount
                    }}
                    onAfterSave={onAfterSave}
                    onAfterDelete={onAfterDelete}
                    defaultView="list"
                />
            </ClosedBasketContext.Provider>
        </Box>;
    }
);

export {ClosedBasketContext};
export default BasketProductList;