import React from "react";
import {
    EntityBaseComponentBuilder, 
    render, 
    ComposerProps, 
    ReactComponent, 
    makeGridRenderer, 
    makeSearchBar, 
    makePaginator
} from "js-react-components";
import CustomerMeta, {
    Customer, 
    CustomerAttributes, 
    CustomerIndices, 
    CustomerSorters, 
    CustomerViews
} from "../../domain/customer";
import {EntityService, HttpOperation} from "js-generic-utilities";
import CustomerCard from "./entity-renderers/customer-card-renderer";
import BaseComposer, {makeComposer} from "../auxiliary/base-composer.component";
import CustomerEditor from "./entity-editors/customer-entity-editor.component";
import LoadingComponent from "../auxiliary/loading.component";
import WindowService from "../../services/auxiliary/window-service";
import EditSuccess from "../auxiliary/edit-success.component";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import { Person } from "@material-ui/icons";
import { texts } from "../../constants";
import {makeMultiAttributeFilterer} from "../auxiliary/filterers/multi-attribute-filterer.component";

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const BaseCustomerComposer = makeComposer({
    EditRenderer: CustomerEditor, 
    meta: CustomerMeta
});

const GridRenderer = wrapRenderer(makeGridRenderer({
    spacing: 2, 
    elementsPerRow: {xs: 12, sm: 4}
}), {
    avatarSize: 150,
    title: texts.missing_elements_title("cliente"),
    body: texts.missing_elements_body,
    Icon: Person
});

const Filterer = makeMultiAttributeFilterer({
    filters: [
        {
            attribute: "name", label: "Nome"
        },
        {
            attribute: "points_code", label: "Codice"
        }
    ]
});

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      windowService: WindowService,
 *      composer: ReactComponent<ComposerProps>
 * }} param0 
 */
export default function makeComponent({
    windowService,
    service, 
    composer, 
    Paginator, 
    itemsPerPage, 
    EntityRenderer,
    alertService,
    search,
    deleteDialogService
}) {
    /**
     * @type {EntityBaseComponentBuilder<"refresh", Customer, CustomerViews, CustomerIndices, CustomerSorters, CustomerAttributes>}
     */
    let builder = render(service);

    builder
        .withMeta(CustomerMeta)
        .withGrid({
            Entity: EntityRenderer || CustomerCard,
            Collection: GridRenderer
        })
        .editEntityWith(CustomerEditor)
        .withBounds({start: 0, end: itemsPerPage || 9})
        .withDefaultView("grid")
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withComponents({
            SearchBar: makeSearchBar("customer-search-bar"),
            Paginator: Paginator || makePaginator({}, "customer-paginator", {
                defaultItemsPerPage: itemsPerPage || 9,
                hideItemsPerPage: true,
                buttons: true,
                allowFirstLastPages: true,
                hideTotalItems: true
            }),
            Filterer: Filterer,
            Sorter: () => null,
            Loader: Loader,
            Alert: () => null
        })
        .withOperations([
            {
                name: "delete",
                handler: (e, {onDelete}) => deleteDialogService.show({
                    message: `Confermare la cancellazione del cliente ${e.name}?`, 
                    onConfirm: () => onDelete(e)
                })
            }
        ])
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: `Cliente ${e.name} ${e.permalink ? "modificato" : "aggiunto"}.`, 
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: `Cliente ${e.name} cancellato.`, 
                severity: "success"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });

    const Component = builder.compose(composer || BaseCustomerComposer);
    return (props) => <Component 
        {...props} 
        operations={props.onSelect ? [
            {
                name: "select",
                handler: props.onSelect
            }
        ] : props.operations} 
    />;
}
