import BasketMeta, {Basket, wrap} from "../../../domain/basket";
import {ReactComponent, EntityEditWrapperInnerComponent, wrapEdit, inject} from "js-react-components";
import componentsContext from "../../../contexts/components.context";
import ClosedBasketContext from "../contexts/closed-basket.context";
import BasketProductListInnerRenderer from "../entity-renderer/auxiliary/basket-product-list-inner-renderer.component";
import LoadingComponent from "../../auxiliary/loading.component";
import { Box } from "@material-ui/core";

const Loader = () => <LoadingComponent size="3em" label="Caricamento..." />;
const Composer = ({
    collection, loader, onSave, entities, basket, basketProductAdderWidth, onClose, onReserve, onSetDiscount
}) => <BasketProductListInnerRenderer
    collection={collection}
    basket={basket}
    loader={loader}
    onSave={onSave}
    entities={entities}
    basketProductAdderWidth={basketProductAdderWidth}
    onClose={onClose}
    onReserve={onReserve}
    onSetDiscount={onSetDiscount}
/>;

const BasketProductListEditor = inject(
    componentsContext,
    ["BasketProduct"],
    /**
     * @type {ReactComponent<EntityEditWrapperInnerComponent<Basket>>}
     */
    ({
        onInputChange, 
        onSubmit, 
        element, 
        originalElement, 
        loading,
        onOperation, 
        BasketProduct
    }) => element && <ClosedBasketContext.Provider 
        value={!wrap(element).isEditable()}
    >
        <Box position="relative">
            {loading && <Loader />}
            <BasketProduct 
                filterAttribute="cart_id" 
                filterValue={element.id} 
                sortAttribute="updated_at"
                sortDirection="desc"
                Composer={Composer}
                ComposerProps={{
                    basket: element,
                    onOperation,
                    onClose: () => onOperation("close", element),
                    onReserve: () => onOperation("reserve", element),
                    onSetDiscount: v => onInputChange("discount", v).then(onSubmit)
                }}
                defaultView="list"
            />
        </Box>
    </ClosedBasketContext.Provider>
);

export default wrapEdit({
    Component: BasketProductListEditor,
    meta: BasketMeta
});