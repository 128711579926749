import BaseProductEditor from "./base-product/base-product-editor.component";
import SimpleProductEditor from "./base-product/simple-product-editor.component";
import ProductVariantEditor from "./product-variant/product-variant-editor.component";
import {EntityRendererProps, wrapEdit} from "js-react-components";
import {Product, ProductWrapper} from "../../../domain/product";

/**
 * @param {EntityRendererProps<Product>} props 
 */
const ProductEditorRenderer = (props) => {
    const {element} = props;
    if(element) {
        switch(element.type) {
            case "simple":
                return <SimpleProductEditor {...props} />;
            case "variant":
                return <ProductVariantEditor {...props} />;
            default:
                return <BaseProductEditor {...props} />;
        }
    }
    return null;
};

export default ProductEditorRenderer;