export {FetchRequestManager};

function isNully(v) {
    return (v === undefined) || (v === null);
}

const makeQueryString = (params) => {
    const names = Object.getOwnPropertyNames(params);
    let out = "";
    if(names.length) {
        for(let i=0; i<names.length; i++) {
            const cName = names[i];
            if(!isNully(params[cName])) {
                out += cName + "=" + params[cName];
                if(i !== (names.length - 1)) {
                    out += "&";
                }
            }
        }
    }
    return out;
}

const getDefaultUrl = (url, defaultUrl) => {
    const regex = new RegExp(/^(http).*$/);
    if(regex.test(url)) {
        return "";
    } else {
        return defaultUrl + "/";
    }
}
const makeOutPars = (defaultParams, params) => {
    params = params || {};
    const out = {
        ...defaultParams, 
        ...params, 
        indexName: params.filterAttribute, 
        indexValue: params.filterValue
    };
    delete out.filterAttribute;
    delete out.filterValue;
    return out;
}
const makeUrl = (url, params) => {
    /*if(params.filterAttribute && params.filterValue) {
        params.indexName = params.filterAttribute;
        params.indexValue = params.filterValue;
    }*/
    if(params.sortAttribute) {
        params.sortBy = params.sortAttribute;
        delete params.sortAttribute;
    }
    if(params.sort) {
        params.sortBy = params.sort;
        delete params.sort;
    }
    const qString = makeQueryString(params);
    return url + (Boolean(qString) ? `?${qString}` : "");
};
const makeRequest = (method, url, data, headers) => {
    if(typeof(data) === "object") {
        data = JSON.stringify(data);
    }
    return fetch(url, {body: data, method: method, headers: headers})
        .then((response) => response.json().then(c => {
            console.log(c);
            if(c.data && (c.data.count !== undefined)) {
                c.data = c.data.count;
            }
            if(c.data && c.message) {
                c.data.message = c.message;
            }
            return Promise.resolve({
                ...c, 
                status: response.status
            });
        }))
        /*.then((response) => {
			console.log(response);
            let decBody;
            const status = response.status;
            if(response.data) {
                console.log(response.data);
                decBody = response.data;
            } else {
				decBody = response;
			}
            if((status >= 400) && (status !== 404)) {
                let failReason;
                if(decBody && decBody.message) {
                    failReason = decBody.message;
                } else {
                    failReason = response.statusText;
                }
                console.log("FetchRequestManager.makeRequest: request failed with status " + status + " and reason '" + failReason + "'");
                return Promise.reject(failReason);
            } else {
                if(status === 404) {
                    return Promise.resolve([]);
                } else {
                    return Promise.resolve(decBody);
                }
            }
        }).catch((reason) => {
            console.log("FetchRequestManager.makeRequest: request failed with reason '" + reason + "'");
            return Promise.reject(reason);
        })*/
}

/**
 * @implements {RequestManager}
 */
class FetchRequestManager {

    static _instance;

    static build(defaultUrl, defaultHeaders) {
        if(!FetchRequestManager._instance) {
            FetchRequestManager._instance = new FetchRequestManager(defaultUrl, defaultHeaders);
        }
        return FetchRequestManager._instance;
    }
    
    /**
     * @type {object}
     */
    _defaultParams;

    /**
     * @type {{
     *      Authorization: string
     * }}
     */
    _defaultHeaders;
    
    /**
     * @type {string}
     */
    _defaultUrl;

    /**
     * @type {number}
     */
    _nRequests;

    constructor(defaultUrl, defaultHeaders) {
        this._defaultParams = {};
        this._defaultHeaders = defaultHeaders;
        this._defaultUrl = defaultUrl;
        this._nRequests = 0;
    }

    /**
     * @param {object} params 
     */
    setDefaultParams(params) {
        if(params) {
            this._defaultParams = {...this._defaultParams, ...params};
        }
    }

    /**
     * @param {object} headers 
     */
    setDefaultHeaders(headers) {
        if(headers) {
            this._defaultHeaders = {...headers};
        }
    }

    post(url, data, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        const outUrl = getDefaultUrl(url, this._defaultUrl) + makeUrl(url, outPars);
        console.log(data);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest("post", outUrl, data, headers || this._defaultHeaders);
    }

    get(url, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        console.log(outPars);
        const outUrl = getDefaultUrl(url, this._defaultUrl) + makeUrl(url, outPars);
        console.log("url = " + outUrl);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest("get", outUrl, undefined, headers || this._defaultHeaders);
    }

    delete(url, params, headers) {
        const outPars = makeOutPars(this._defaultParams, params);
        const outUrl = getDefaultUrl(url, this._defaultUrl) + makeUrl(url, outPars);
        console.log("url = " + outUrl);
        this._nRequests++;
        this.printNumRequests();
        return makeRequest("delete", outUrl, undefined, headers || this._defaultHeaders);
    }

    printNumRequests() {
        console.log("FetchRequestManager: got " + this._nRequests + " requests.");
    }
}