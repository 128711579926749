import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductStatService({requestManager}) {
    const handlers = [
        handle("get").with({
            method: "GET", 
            path: (d, e) => (e.filterAttribute === "product_id") && e.filterValue ? `/product/${e.filterValue}/stat` : `/product_stat`
        }).build(),
        handle("count").with(
            {method: "GET", 
            path: (d, e) => (e.filterAttribute === "product_id") && e.filterValue ? `/product/${e.filterValue}/stat/count` : `/product_stat/count`
        }).build(),
        handle("delete").with({method: "DELETE", path: e => {
            console.log(e);
            return `/product/${e.product_id}/stat/${e.id}`;
        }}).build()
    ];

    return serve(handlers).using(requestManager).build();
}