import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  IconButton,
  Container,
  Grid,
  withStyles,
  Typography,
  Box,
  Badge,
  Tooltip
} from "@material-ui/core";
import { Menu, KeyboardArrowDown, ShoppingCart } from "@material-ui/icons";
import { inject } from "js-react-components";
import servicesContext from "../contexts/services.context";
import BasketSideViewService from "./basket/side-view/basket-side-view.service";
import clsx from "clsx";

const AppBarButton = ({ label, onClick, classes }) => (
  <Button
    variant="text"
    endIcon={<KeyboardArrowDown className={classes.whiteText} />}
  >
    <Typography component="span" variant="body1" className={classes.whiteText}>{label}</Typography>
  </Button>
);

export default withStyles({
  whiteText: {
    color: "white"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  title: {
    //margin: "0 auto"
  }
})(
    inject(
      servicesContext,
      ["basketSideView"],
      /**
       * @param {{basketSideView: BasketSideViewService}}
       */
      ({ 
        classes, 
        buttons, 
        onMenuClick, 
        AppBarProps, 
        onBasketClick, 
        basketSideView,
        MenuIcon,
        BasketIcon,
        title,
        imgMaxWidth,
        TitleProps,
        basketTriggerDisabled
      }) => {
        const [basket, setBasket] = useState(null);
        const lel = e => setBasket(e);
        useEffect(() => {
          basketSideView.onRefresh(lel);
          return () => basketSideView.remove(lel);
        }, []);
        MenuIcon = MenuIcon || Menu;
        BasketIcon = BasketIcon || ShoppingCart;
        const onBasketClickFragment = <IconButton onClick={onBasketClick} disabled={basketTriggerDisabled}>
        <Badge badgeContent={basket && basket.product_count} color="secondary">
          <BasketIcon className={classes.whiteText} />
        </Badge>
      </IconButton>;
        return (
          <AppBar {...AppBarProps}>
            <Container maxWidth={false}>
              <Grid container alignItems="center" spacing={2}>
                {onMenuClick && <Grid item>
                  <IconButton onClick={onMenuClick}>
                    <MenuIcon className={classes.whiteText} />
                  </IconButton>
                </Grid>}
                <Grid item xs>
                  <Box margin="0.5em auto" display="flex" justifyContent="center" alignItems="center" style={{width: "100%"}}>
                    <img 
                      src="https://www.italwebcom.it/singer/prodotti/app/img/logo.png" 
                      style={{
                        display: "block", 
                        maxWidth: imgMaxWidth || "200px", 
                        margin: !title ? "auto" : undefined,
                        marginRight: title ? "1em" : "auto"
                      }} 
                      alt="logo"
                    />
                    {title && <Typography 
                      {...(TitleProps || {})}
                      component="header" 
                      variant="h2" 
                      className={clsx([classes.whiteText, classes.title])}
                    >
                      {title}
                    </Typography>}
                  </Box>
                </Grid>
                {buttons && (
                  <Grid item>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      <AppBarButton label="Attributi" classes={classes} />
                      <AppBarButton label="Prodotti" classes={classes} />
                      <AppBarButton label="Carrelli" classes={classes} />
                      <AppBarButton label="Clienti" classes={classes} />
                    </Box>
                  </Grid>
                )}
                {onBasketClick && <Grid item>
                  <Grid container spacing={1} justify="flex-end" alignItems="center">
                    <Grid item>
                      {basketTriggerDisabled ? <Tooltip 
                        title={<Typography component="span" variant="body2" style={{textAlign: "center"}}>Il carrello attivo non è al momento disponibile.</Typography>}
                        style={{cursor: "help", opacity: 0.5}}
                      >
                        <span>{onBasketClickFragment}</span>
                      </Tooltip> : onBasketClickFragment}
                    </Grid>
                  </Grid>
                </Grid>}
              </Grid>
            </Container>
          </AppBar>
        );
      }
    )
);
