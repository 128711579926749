import React, { Fragment, useState } from "react";
import {Customer} from "../../../domain/customer";
import {EntityRendererProps, ReactComponent, inject} from "js-react-components";
import {
  Card,
  CardHeader,
  CardActionArea,
  Avatar,
  Button,
  ButtonGroup,
  CardActions,
  IconButton
} from "@material-ui/core";
import { Person, MoreVert } from "@material-ui/icons";
import EntitySideMenu from "../../auxiliary/entity-side-menu.component";
import servicesContext from "../../../contexts/services.context";

/**
 * @type {ReactComponent<EntityRendererProps<Customer>>}
 */
const CustomerCard = inject(
  servicesContext,
  ["windowService", "deleteDialogService"],
  ({ onDelete, onSelect, onOperation, element, selectOnly, windowService, deleteDialogService }) => {
    const [menuTarget, setMenuTarget] = useState(null);
    return <Fragment>
      <EntitySideMenu
          baseId={`customer-${element.id}-card-side-menu`}
          key={`${element.id}-side-menu`}
          title={element.name}
          MenuProps={{
            anchorEl: menuTarget,
            anchorOrigin: {
              horizontal: "center",
              vertical: "top"
            }
          }}
          onClose={() => setMenuTarget(null)}
          open={Boolean(menuTarget)}
          operations={[
            { label: "Visualizza", onClick: () => onSelect(element) },
            { label: "Cancella", onClick: () => deleteDialogService.show({
                message: `Confermare la cancellazione del cliente ${element.name}?`, 
                onConfirm: () => onDelete(element)
            }) },
            { label: "Visualizza carrelli", onClick: () => onOperation("baskets", element) }
          ]}
        />
      <Card data-testid={`customer-${element.id}-card`}>
        <CardHeader
          avatar={<Avatar><Person /></Avatar>}
          title={element.name}
          titleTypographyProps={{
            "data-testid": `customer-${element.id}-card-name`
          }}
          subheader={`Codice ${element.points_code}`}
          subheaderTypographyProps={{
            "data-testid": `customer-${element.id}-card-code`
          }}
          action={
            !selectOnly ? <IconButton 
              data-testid={`customer-${element.id}-card-menu-trigger`} 
              onClick={(evt) => setMenuTarget(evt.target)}
            >
              <MoreVert />
            </IconButton> : <Button 
              variant="text" 
              color="primary" 
              onClick={() => onOperation("select", element)}
              data-testid={`customer-${element.id}-card-select`}
            >
              Seleziona
            </Button>
          }
        />
        {!selectOnly && <CardActionArea>
          <CardActions>
            <ButtonGroup fullWidth>
              <Button 
                variant="text" 
                color="primary" 
                onClick={() => onSelect(element)}
                data-testid={`customer-${element.id}-card-edit`}
              >
                Modifica
              </Button>
              <Button 
                variant="text" 
                color="primary"
                onClick={() => windowService.href(`/customer/${element.id}/baskets`)}
                data-testid={`customer-${element.id}-card-baskets`}
              >
                Carrelli
              </Button>
            </ButtonGroup>
          </CardActions>
        </CardActionArea>}
      </Card>
    </Fragment>;
  }
);

export function makeCustomerCard(search) {
  return (props) => <CustomerCard {...props} selectOnly={search} />;
}

export default CustomerCard;