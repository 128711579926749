import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeBasketProductService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => (e.filterAttribute === "cart_id") ? `/cart/${e.filterValue}/product` : `/basket_product`}).build(),
        handle("count").with({method: "GET", path: (d, e) => (e.filterAttribute === "cart_id") ? `/cart/${e.filterValue}/product/count` : `/basket_product/count`}).build(),
        handle("save").with({method: "POST", path: e => `/cart/${e.cart_id}/product`}).build(),
        handle("update").with({method: "POST", path: e => e.permalink}).build(),
        handle("delete").with({method: "DELETE", path: e => e.permalink}).build()
    ];

    return serve(handlers).using(requestManager).build();
}