import makeEditor from "../../auxiliary/base-entity-editor.component";
import ProductQuantityAlterationMeta, {ProductQuantityAlteration, wrap} from "../../../domain/product-quantity-alteration";
import {SwapHoriz} from "@material-ui/icons";
import {ReactComponent, EntityDetailRendererProps, inject} from "js-react-components";
import ServicesContext from "../../../contexts/services.context";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import { EntityService, HttpOperation, observe, ObserverPool, remove } from "js-generic-utilities";
import { CircularProgress, Typography, RadioGroup, FormControlLabel, Radio, withStyles } from "@material-ui/core";
import ProductShortOverview from "../../product/entity-renderers/product-short-overview.component";
import { useEffect, useState } from "react";

const TypeSelectionRenderer = withStyles({
    radioGroup: {
        flexDirection: "row",
        height: "100%"
    }
})(
    ({value, onChange, classes}) => <RadioGroup className={classes.radioGroup}>
        {[
            {
                v: "increase",
                label: "Caricamento"
            },
            {
                v: "return",
                label: "Reso cliente"
            },
            {
                v: "decrease",
                label: "Scarico"
            }
        ].map(({v, label}) => <FormControlLabel
            key={v}
            control={<Radio />}
            checked={value === v}
            onChange={(e, checked) => checked && onChange(v)}  
            label={label}
        />)}
    </RadioGroup>
);

const ProductRenderer = inject(
    ServicesContext,
    ["product"],
    /**
     * @param {{product: EntityService<HttpOperation>}}
     */
    ({product, value, element}) => {
        const actAmount = wrap(element).getActualAmount();
        return <AsyncEntityLoader
            key={actAmount}
            id={value}
            onGet={i => product.serve("get", null, {filterAttribute: "id", filterValue: i})}
            Renderer={({entity}) => entity && <ProductShortOverview element={entity} newStock={actAmount} />}
            LoadingComponent={CircularProgress}
        />;
    }
);

/**
 * @type {ReactComponent<EntityDetailRendererProps<ProductQuantityAlteration>>}
 */
const Editor = makeEditor({
    meta: ProductQuantityAlterationMeta,
    title: "Modifica stock prodotto",
    icon: <SwapHoriz />,
    config: {
        noCurrentDetails: true,
        noHeader: true,
        isValid: e => e.type && e.amount && e.date && (parseInt(e.amount) > 0)
    },
    attributes: [
        {
            id: "type",
            Renderer: TypeSelectionRenderer,
            GridProps: {xs: 12, sm: 12}
        },
        {
            id: "date",
            label: "Data",
            type: "date"
        },
        {
            id: "amount",
            label: "Quantità",
            type: "number",
            TextFieldProps: {
                inputProps: {
                    min: 0,
                    step: 1
                }
            }
        },
        {
            id: "product_id",
            Renderer: ProductRenderer,
            GridProps: {xs: 12, sm: 12}
        }
    ]
});

export default Editor;