import React, { useState, useEffect, memo } from "react";
import {ReactComponent} from "js-react-components";


/**
 * @template T
 * @typedef {{
 *      onGet: (id: number|string) => Promise<T>,
 *      id: string|number,
 *      Renderer: ReactComponent<{entity: T}>,
 *      LoadingComponent: ReactComponent<{}>
 * }} AsyncEntityLoaderProps
 */

/**
 * @template T
 * @param {AsyncEntityLoaderProps<T>} param0 
 */
const Component = ({
    id, 
    onGet, 
    Renderer, 
    LoadingComponent,
    onError, 
    RendererProps, 
    collection
}) => {
    const [state, setState] = useState({
        entity: null, loading: false
    });

    useEffect(() => {
        //console.log("effect");
        setState({loading: true});
        onGet(id)
            .then(element => {
                console.log(element);
                if(!collection && (element instanceof Array)) {
                    element = element[0];
                }
                setState({
                    entity: element, 
                    loading: false
                })
            })
            .catch(err => {
                onError ? onError(err) : console.log(err);
                setState({loading: false});
            });
    }, [id]);

    return state.loading ? <LoadingComponent /> : <Renderer {...(RendererProps || {})} element={state.entity} entity={state.entity} />;
};

export default memo(
    Component,
    (p, n) => p.id === n.id
);