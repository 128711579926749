import React, {useState, memo} from "react";
import ProductDetailsEditor from "../product-details-entity-editor.component";
import ProductOverview from "../../entity-renderers/product-overview.component";
import ComponentsContext from "../../../../contexts/components.context";
import {HTMLCarousel as Carousel, CarouselService, CustomCarouselService, inject} from "js-react-components";
import {Grid, Container, Divider, ButtonGroup, Button, Tabs, Tab} from "@material-ui/core";
import ProductImageEditor from "../../image/product-image-editor.component";
import LoadingComponent from "../../../auxiliary/loading.component";
import moment from "moment";

const SectionSelector = memo(
    ({onSelect, selected}) => <Tabs value={selected} onChange={(e, v) => onSelect(v)}>
        {["Dati", "Costi", "Immagini", "Caricamenti e resi", "Statistiche"].map((s, i) => <Tab 
            value={i}
            label={s}
            key={s}
        />)}
    </Tabs>,
    (p, n) => p.selected === n.selected
)

/**
 * @type {CustomCarouselService}
 */
const carouselService = CarouselService.make();
const Loader = () => <LoadingComponent size="3em" label="Caricamento..." />

const Component = inject(
    ComponentsContext,
    ["ProductStat", "ProductQuantityAlterationAlt", "ProductCost"],
    ({element, onSave, ProductStat, ProductQuantityAlterationAlt, ProductCost, loading}) => {
        const [section, setSection] = useState(0);
        const onSetSection = n => {
            carouselService.switch(n);
            setSection(n);
        };
        return element && <Container maxWidth={false} disableGutters style={{position: "relative"}}>
            {loading && <Loader />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ProductOverview product={element} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <SectionSelector onSelect={onSetSection} selected={section} />
                    </Grid>
                    <Grid item xs={12}>
                        {(section === 0) && <ProductDetailsEditor element={element} onSave={onSave} />}
                        {(section === 1) && <ProductCost productId={element.id} />}
                        {(section === 2) && <ProductImageEditor product={element} />}
                        {(section === 3) && <ProductQuantityAlterationAlt
                            productId={element.id}
                            defaultView="table"
                            ComposerProps={{
                                noLabels: true,
                                EditRendererProps: {
                                    element: {product_id: element.id, date: moment().format("YYYY-MM-DD")}
                                }
                            }}
                        />}
                        {(section === 4) && <ProductStat
                            filterAttribute="product_id"
                            filterValue={element.id}
                            CollectionRendererProps={{product: element}}
                        />}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    }
)

export default Component;
