import React, { Fragment } from "react";
import ProductCostMeta, {ProductCost} from "../../domain/product-cost";
import {CollectionRendererProps} from "js-react-components";
import {CollectionUtilityWrapper} from "js-generic-utilities";
import {Container, Grid, Typography, makeStyles, Card, CardHeader, CardContent, Badge, Avatar} from "@material-ui/core";
import { Euro } from "@material-ui/icons";
import ProductCostEditorContext from "./product-cost-editor.context";

const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: "bold",
        color: "grey"
    }
}));

const CostCard = ({elements, badge, title, subheader, EntityRenderer, onSave}) => {
    const els = elements.unwrap();
    return <Card>
        <CardHeader
            avatar={<Badge badgeContent={badge} color="primary">
                <Avatar>
                    <Euro />
                </Avatar>    
            </Badge>}
            title={title}
            titleTypographyProps={{
                variant: "h4",
                fontWeight: "bold"
            }}
            subheader={subheader || title}
        />
        <CardContent>
            <Grid container spacing={2}>
                {els.length && <Fragment>
                    <Grid item xs={12} key={ProductCostMeta.keyGetter(els[0])}>
                        <EntityRenderer element={els[0]} onSave={onSave} />
                    </Grid>
                    <Grid item xs={12} key={ProductCostMeta.keyGetter(els[1])}>
                        <ProductCostEditorContext.Provider value={{baseValue: els[0].cost}}>
                            <EntityRenderer element={els[1]} onSave={onSave} />
                        </ProductCostEditorContext.Provider>
                    </Grid>    
                </Fragment>}
            </Grid>
        </CardContent>
    </Card>;
};

/**
 * @param {CollectionRendererProps<ProductCost>} param0
 */
const Component = ({elements, EntityRenderer, onSave}) => {
    
    /**
     * @type {CollectionUtilityWrapper<ProductCost>}
     */
    const wrapped = CollectionUtilityWrapper.wrap(elements, ProductCostMeta.matcher);
    const styles = useStyles();

    const workshopCosts = wrapped.filter(e => e.product_list_id < 2);
    const wooCosts = wrapped.filter(e => e.product_list_id >= 2);

    console.log(workshopCosts.unwrap());
    console.log(wrapped.unwrap());

    return <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <CostCard 
                    elements={workshopCosts}
                    EntityRenderer={EntityRenderer}
                    onSave={onSave}
                    title="Costi magazzino"
                    subheader="con e senza sconto"
                    badge="M"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CostCard 
                    elements={wooCosts}
                    EntityRenderer={EntityRenderer}
                    onSave={onSave}
                    title="Costi woocommerce"
                    subheader="con e senza sconto"
                    badge="W"
                />
            </Grid>
        </Grid>
    </Container>
};

export default Component;