const data = [
    {id: 1, name: "prod1", description: "prod1", quantity: 10, variants_number: 1, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 2, name: "prod2", description: "prod1", quantity: 11, variants_number: 1, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 3, name: "prod3", description: "prod1", quantity: 10, variants_number: 1, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 4, name: "prod4", description: "prod1", quantity: 11, variants_number: 0, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 5, name: "prod5", description: "prod1", quantity: 10, variants_number: 0, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 6, name: "prod6", description: "prod1", quantity: 11, variants_number: 0, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 7, name: "prod7", description: "prod1", quantity: 10, variants_number: 0, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 8, name: "prod8", description: "prod1", quantity: 11, variants_number: 0, type: "variable", image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 9, name: "prod9", description: "prod1", quantity: 7, type: "variant", variable_product_id: 1, variant_feature_values: [1], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/184777/0.jpg?dummy=126595"},
    {id: 10, name: "prod10", description: "prod1", quantity: 0, type: "variant", variable_product_id: 1, variant_feature_values: [2], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/81610/0.jpg?dummy=126595"},
    {id: 11, name: "prod11", description: "prod1", quantity: 0, type: "variant", variable_product_id: 2, variant_feature_values: [1], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/145498/0.jpg?dummy=126595"},
    {id: 12, name: "prod12", description: "prod1", quantity: 3, type: "variant", variable_product_id: 1, variant_feature_values: [2], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/184777/0.jpg?dummy=126595"},
    {id: 13, name: "prod13", description: "prod1", quantity: 7, type: "variant", variable_product_id: 3, variant_feature_values: [1], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/46955/0.jpg?dummy=126595"},
    {id: 14, name: "prod14", description: "prod1", quantity: 3, type: "variant", variable_product_id: 3, variant_feature_values: [2], image: "https://www.italwebcom.it/singer/prodotti/server/img//base/81610/0.jpg"}
];

data.forEach(d => d.code = d.name);

export default data;