import {
    render, EntityBaseComponentBuilder, makeSearchBar, makePaginator, makeGridRenderer, ListCollectionRenderer
} from "js-react-components";
import {EntityService, HttpOperation} from "js-generic-utilities";
import AttributeMeta, {Attribute} from "../../domain/attribute";
import AttributeListRenderer from "./entity-renderers/attribute-list-renderer.component";
import AttributeEditor from "./entity-editors/attribute-entity-editor.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import LoadingComponent from "../auxiliary/loading.component";
import AlertService from "../auxiliary/alert-component/alert-service";
import EditSuccess from "../auxiliary/edit-success.component";
import {IconComponents, texts} from "../../constants";
import {wrapRenderer} from "../auxiliary/missing-elements.component";

const BaseAttributeComposer = makeComposer({EditRenderer: AttributeEditor});
/**
 * @typedef {"grid"} ProductViews 
 * @typedef {EntityBaseComponentBuilder<"refresh", Product, ProductViews, ProductIndices, ProductSorters, ProductAttributes>} ProductBaseComponentBuilder
 */

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />;
const wrapOptions = {
    avatarSize: 150,
    title: texts.missing_elements_title("attributo"),
    body: texts.missing_elements_body,
    Icon: IconComponents.attribute
};

const GridCollectionRenderer = wrapRenderer(makeGridRenderer({
    spacing: 1,
    elementsPerRow: {
        xs: 6,
        sm: 4
    }
}), wrapOptions);

const WrappedListCollectionRenderer = wrapRenderer(ListCollectionRenderer, wrapOptions);

/**
 * @typedef {"name" | "description" | "id"} AttributeAttributes
 * @typedef {"name"} AttributeIndices
 * @typedef {"name"} AttributeSorters
 * @typedef {"list"} AttributeViews
 */

/**
 * @param {{
 *      service: EntityService<HttpOperation>,
 *      alertService: AlertService
 * }} param0
 */
export default function makeAttributeBaseComponent({
    service, GridRenderer, composer, alertService, deleteDialogService, ListEntityRenderer
}) {

    /**
     * @type {EntityBaseComponentBuilder<"refresh", Attribute, AttributeViews, AttributeIndices, AttributeSorters, AttributeAttributes>}
     */
    let builder = render(service);

    builder
        .withMeta(AttributeMeta)
        .withList({
            Entity: ListEntityRenderer || AttributeListRenderer,
            Collection: WrappedListCollectionRenderer
        })
        .withGrid({
            Collection: GridCollectionRenderer,
            Entity: GridRenderer
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .editEntityWith(AttributeEditor)
        .withComponents({
            SearchBar: makeSearchBar("attribute-search-bar"),
            Paginator: makePaginator({}, "attribute-paginator", {
                hideItemsPerPage: true,
                buttons: true,
                defaultItemsPerPage: 8,
                allowFirstLastPages: true
            }),
            Sorter: () => null,
            Filterer: () => null,
            Alert: () => null,
            Loader: Loader
        })
        .withBounds({
            start: 0, 
            end: 8
        })
        .withSearchBarAttribute({
            attribute: "name",
            label: "Nome attributo"
        })
        .withDefaultView("list")
        .withOperations([
            {
                name: "delete",
                handler: (e, {onDelete}) => deleteDialogService.show({
                    message: `Confermare la cancellazione dell'attributo ${e.name}?`, 
                    onConfirm: () => onDelete(e)
                })
            }
        ])
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: <EditSuccess 
                    entity={e} 
                    title={`Attributo ${e.name} modificato.`} 
                    entityName="attribute" 
                />, 
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: `Attributo ${e.name} cancellato.`, 
                severity: "success"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });

        return builder.compose(composer || BaseAttributeComposer);
}