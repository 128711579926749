import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{
 *      requestManager: RequestManager
 * }} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductVariantService({requestManager}) {
    const handlers = [
        handle("get")
            .with({
                method: "GET", 
                path: (d, e) => e.filterAttribute === "variable_product_id" ? `/variable_product/${e.filterValue}/variant` : `/product_variant`
            })
            .build(),
        handle("count")
            .with({
                method: "GET", 
                path: (d, e) => e.filterAttribute === "variable_product_id" ? `/variable_product/${e.filterValue}/variant/count` : `/product_variant/count`
            })
            .build(),
        handle("save")
            .with({
                method: "POST", 
                path: e => `/variable_product/${e.variable_product_id}/variant`
            })
            .build(),
        handle("update")
            .with({
                method: "POST", 
                path: e => `/variable_product/${e.variable_product_id}/variant/${e.id}`
            })
            .build(),
        handle("delete")
            .with({
                method: "DELETE", 
                path: e => {
                    console.log(e);
                    return `/variable_product/${e.variable_product_id}/variant/${e.id}`;
                }
            })
            .build()
    ];

    return serve(handlers).using(requestManager).build();
}