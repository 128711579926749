import {EntityMeta} from "js-generic-utilities";
import { getDefaultMeta, ValidationWrapper } from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";
import moment from "moment";

/**
 * @typedef {"list" | "grid"} Basketviews
 * @typedef {"date"} BasketIndices
 * @typedef {"date"} BasketSorters
 * @typedef {"id" | "date" | "cost" | "status" | "product_count" | "customer_id"} BasketAttributes
 * @typedef {"open" | "closed" | "paid"} BasketStatus
 * @typedef {{
 *    id: number,
 *    date: string,
 *    customer_id: number,
 *    cost: number,
 *    product_count?: number,
 *    status: BasketStatus
 * }} Basket
 */

/**
 * @type {EntityMeta<Basket>}
 */
const meta = getDefaultMeta({
  Constructor: function(v) {
    v = v ? {...v} : {};
    if(!v.date) {
      v.date = moment().format("YYYY-MM-DD");
    }
    return v;
  }
});

export class BasketWrapper {

  /**
   * @type {Basket}
   */
  _data;

  constructor(data) {
    this._data = {...data};
  }
  
  /**
   * @param {"open" | "closed" | "paid"} target 
   */
  is(target) {
    return this._data.status === target;
  }

  valid() {
    return this._data && (typeof(this._data.id) === "number");
  }

  isClosed() {
    return this.is("paid") || this.is("closed");
  }

  isReserved() {
    return this.is("reserved");
  }

  isOpen() {
    return this.is("open");
  }

  isEmpty() {
    return !this._data.product_count;
  }

  close() {
    this.status("paid");
    return this;
  }

  reserve() {
    this.status("reserved");
    return this;
  }

  isClosable() {
    return this.isOpen() || this.isReserved();
  }

  isReservable() {
    return this.isOpen();
  }

  isEditable() {
    return this.isOpen();
  }

  isFromWoocommerce() {
    return Boolean(parseInt(this._data.woocommerce)); 
  }

  description() {
    const added = "";//this.isFromWoocommerce() ? "woocommerce" : "magazzino";
    switch(this._data.status) {
      case "open":
        return `Carrello ${added} aperto`;
      case "reserved":
        return `Carrello ${added} sospeso`;
      default:
        return `Carrello ${added} chiuso`;
    }
  }

  status(t) {
    this._data.status = t || "paid";
    return this;
  }

  get() {
    return this._data;
  }
}

export function wrap(data) {
  return new BasketWrapper(data);
}

export function validate(n, data) {
  let w = new ValidationWrapper(data, {date: regexes.date});
  return (n instanceof Array) ? w.validateMulti(n) : w.validate(n);
}

export default meta;
