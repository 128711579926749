import settingsContext from "../../../../contexts/settings.context";
import moment from "moment";
import clsx from "clsx";
import {inject} from "js-react-components";
import {BasketSideViewContext} from "../../../../components/basket/side-view/basket-side-view.component";
import {wrap} from "../../../../domain/basket";
import {
    Button, ListItem, ListItemAvatar, Tooltip, Typography, MenuItem, 
    Badge, Avatar, ListItemText, ListItemSecondaryAction, ButtonGroup, IconButton, Menu
} from "@material-ui/core";
import {More, ShoppingCart, MoreVert} from "@material-ui/icons";
import {round} from "../../../../utils/misc-utils-functions";
import { createContext, Fragment, useState } from "react";
import servicesContext from "../../../../contexts/services.context";
import {Link} from "react-router-dom";
import AsyncCustomerRenderer from "./async-customer-renderer.component";

const BasketRendererOperationsList = ({basket, onShow, onDelete, onClose, onReserve}) => {
    const [ref, setRef] = useState(null);
    return <Fragment>
        <IconButton onClick={evt => setRef(evt.target)}>
            <MoreVert />
        </IconButton>
        <Menu open={Boolean(ref)} anchorEl={ref} onBackdropClick={() => setRef(null)}>
            {[
                {
                    handler: onShow,
                    label: "Visualizza"
                },
                {
                    handler: onDelete,
                    label: "Cancella"
                },
                {
                    handler: onClose,
                    label: "Chiudi"
                },
                {
                    handler: onReserve,
                    label: "Sospendi"
                }
            ].map(({handler, label}) => <MenuItem onClick={handler}>
                {label}
            </MenuItem>)}
        </Menu>
    </Fragment>
}

const DefaultBasketRenderer = inject(
    settingsContext,
    ["smallScreen"],
    inject(
        servicesContext,
        ["windowService"],
        ({basket, classes, onShow, shown, onOperation, onBasketDelete, smallScreen, windowService}) => {
            const cBasket = basket;
            return cBasket ? <BasketSideViewContext.Consumer>
                {({basket}) => {
                    const sel = basket && (cBasket.id === basket.id);
                    const wrapped = wrap(cBasket);
                    const woo = wrapped.isFromWoocommerce();
                    const btn = <Button
                        key="set_selected" 
                        onClick={() => onOperation("setActive", cBasket)} 
                        variant="text" 
                        color="secondary"
                        disabled
                    >
                        Attivo
                    </Button>;
                    return <ListItem
                        key={cBasket.id} 
                        className={classes.listItem}
                    >
                        <ListItemAvatar>
                            <Tooltip
                                title={<Typography component="p" variant="body2">
                                    {wrapped.description()}
                                </Typography>} 
                                placement="top" 
                                arrow
                            >
                                <Badge badgeContent={woo ? "W" : "M"} color={woo ? "secondary" : "primary"}>
                                    <Avatar className={clsx([classes[cBasket.status], classes.basketTooltip])}>
                                        <ShoppingCart />
                                    </Avatar>
                                </Badge>
                            </Tooltip>
                        </ListItemAvatar>
                        <ListItemText 
                            secondary={`${wrapped.description()} del ${moment(cBasket.date).format("DD-MM-yyyy")}`} 
                            primary={
                                `${cBasket.customer_name}, ${round(cBasket.discounted_cost || cBasket.cost, 3) || "?"} euro, ${cBasket.product_count || "?"} prodotti`
                            } 
                            primaryTypographyProps={{
                                style: {
                                    fontWeight: "bold", 
                                    color: "rgb(111, 111, 111)"
                                }
                            }}
                        />
                        <ListItemSecondaryAction>
                            {!smallScreen ? <ButtonGroup>
                                {wrapped.isClosed() ? <Button variant="text" color="primary" onClick={onShow}>
                                        Visualizza
                                    </Button> : <Link to={`/basket/${cBasket.id}`}>
                                    <Button variant="text" color="primary">
                                        Modifica
                                    </Button>
                                </Link>}
                                
                                    {wrapped.isClosable() && <Button 
                                        key="basket_close" 
                                        onClick={() => onOperation("close", cBasket)}
                                        variant="text"
                                        color="primary"
                                    >
                                        Chiudi
                                    </Button>}
                                    {wrapped.isReservable() && <Button 
                                        key="basket_reserve" 
                                        onClick={() => onOperation("reserve", cBasket)} 
                                        variant="text" 
                                        color="primary"
                                    >
                                        Sospendi
                                    </Button>}
                                    <Button 
                                        key="basket_delete" 
                                        onClick={onBasketDelete} 
                                        variant="text" 
                                        color="secondary"
                                    >
                                        Cancella
                                    </Button>
                                    {(typeof(onOperation) === "function") && (sel ? <Badge 
                                        color="secondary" 
                                        badgeContent=""
                                    >
                                        {btn}
                                    </Badge>: btn)}
                            </ButtonGroup> : <BasketRendererOperationsList
                                basket={cBasket}
                                onShow={() => windowService.href(`/basket/${cBasket.id}`)}
                                onDelete={onBasketDelete}
                                onClose={() => onOperation("close", cBasket)}
                                onReserve={() => onOperation("reserve", cBasket)}
                            />}
                        </ListItemSecondaryAction>
                    </ListItem>;
                }}
            </BasketSideViewContext.Consumer> : null;
        }   
    ) 
);

export default DefaultBasketRenderer;