import {EntityMeta} from "js-generic-utilities";
import {getDefaultMeta} from "./auxiliary/domain-misc-functions";

/**
 * @typedef {{
 *    id: number,
 *    type: string,
 *    content: string,
 *    created_at: string,
 *    username?: string
 * }} Alert
 */

/**
 * @type {EntityMeta<Alert>}
 */
const meta = getDefaultMeta();

class AlertWrapper {

    /**
     * @type {Alert}
     */
    _data;

    constructor(data) {
        this._data = data;
    }

    isError() {
        let regex = new RegExp(/^.*(error).*$/);
        return regex.test(this._data.type);
    }

    getTypeDescription() {
        switch(this._data.type) {
            case "woocommerce_error":
                return "Errore woocommerce";
            case "general_workshop_error":
                return "Errore magazzino";
            case "workshop_operation":
                return "Operazione magazzino";
            default:
                return "Notifica generica";
        }
    }

    getUsername() {
        return this._data.username || "Utente sconosciuto";
    }
}

export function wrap(data) {
    return new AlertWrapper(data);
}

export default meta;
