import React from "react";
import {EntityRendererProps, ReactComponent, wrapEdit, inject} from "js-react-components";
import BasketProductMeta, {BasketProduct} from "../../../domain/basket-product";
import {wrap} from "../../../domain/product";
import {
    ListItem, ListItemAvatar, ListItemText, 
    ListItemSecondaryAction, Avatar, makeStyles, 
    ButtonGroup, Button, Box, Chip, Typography, Badge, CircularProgress, Grid, Container, withStyles
} from "@material-ui/core";
import {DeleteForever} from "@material-ui/icons";
import BasketProductQuantityEditor from "../entity-editor/basket-product-quantity-editor.component";
import { Fragment } from "react";
import ServicesContext from "../../../contexts/services.context";
import AsyncEntityLoader from "../../auxiliary/async-entity-loader/async-entity-loader.component";
import { round, shorten } from "../../../utils/misc-utils-functions";
import {ClosedBasketContext} from "../../basket/entity-renderer/basket-product-list-renderer.component";
import SettingsContext from "../../../contexts/settings.context";
import NameShortener from "../../auxiliary/name-shortener.component";
import {red} from "@material-ui/core/colors";

const ProdDetailsRenderer = inject(
    SettingsContext,
    ["smallScreen"],
    withStyles({
        subheader: {
            color: "rgb(134, 134, 134)"
        }
    })(
        ({entity, smallScreen, classes}) => entity && <Fragment>
            <Typography component="header" variant="body1" gutterBottom>
                {smallScreen ? <NameShortener text={entity.name} length={45} /> : entity.name}
            </Typography>
            <Typography component="p" variant="body2" className={classes.subheader}>
                {wrap(entity).printType()}
            </Typography>
        </Fragment>
    )
);

const ProdImgRenderer = ({entity}) => entity && <Avatar>
    <img 
        src={entity.image} 
        alt={entity.name} 
        style={{maxWidth: "100%", display: "block"}} 
    />
</Avatar>;

const AuxRenderer = withStyles({
    cost: {
        color: "green",
        fontWeight: "bold"
    },
    quantity: {
        fontWeight: "bold"
    },
    quantityBadge: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        width: "30px",
        backgroundColor: red["300"],
        color: "white",
        borderRadius: "50%"
    }
})(
    inject(
        SettingsContext,
        ["smallScreen"],
        ({
            entity, onSave, classes, onDelete, closed, productService, deleteDialogService, smallScreen
        }) => entity && <Fragment>
            <ListItemAvatar>
                <AsyncEntityLoader
                    id={entity.product_id}
                    LoadingComponent={CircularProgress}
                    Renderer={ProdImgRenderer}
                    onGet={i => productService.serve("get", null, {filterAttribute: "id", filterValue: i})}
                />
            </ListItemAvatar>
            <ListItemText 
                style={{marginLeft: "0.5em"}}
                disableTypography
                primary={<Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <AsyncEntityLoader
                            id={entity.product_id}
                            LoadingComponent={CircularProgress}
                            Renderer={ProdDetailsRenderer}
                            onGet={i => productService.serve("get", null, {filterAttribute: "id", filterValue: i})}
                        />
                    </Grid>
                    <Grid item xs>
                        <Box display="flex" justifyContent="flex-end">
                            <Box 
                                display="flex" 
                                alignItems="center" 
                                minWidth="100px" 
                                justifyContent={closed ? (smallScreen ? "flex-start" : "flex-end") : "space-around"}
                            >
                                <Box className={classes.quantityBadge}>
                                    <Typography component="p" variant="body1">{entity.quantity}</Typography>
                                </Box>
                                <Box margin="0 1em">
                                    <Typography component="p" variant="body1">&times;</Typography>
                                </Box>
                                <Typography component="p" variant="h4" className={classes.cost}>
                                    {round(entity.cost, 2)} &euro;
                                </Typography>
                            </Box>
                            {!closed && <Box margin="0 1.5em">
                                <BasketProductQuantityEditor
                                    key={entity.quantity}
                                    element={entity}
                                    onSave={onSave}
                                />
                            </Box>}
                            {!closed && <Button 
                                variant="text" 
                                color="secondary"
                                onClick={() => deleteDialogService.show({
                                    message: `Confermare la cancellazione del prodotto?`, 
                                    onConfirm: () => onDelete(entity)
                                })}
                            >
                                Rimuovi
                            </Button>}
                        </Box>
                    </Grid>
                </Grid>}
            />
        </Fragment>
    )
);

const ProductListRenderer = inject(
    ServicesContext,
    ["product", "deleteDialogService"],
    /**
     * @type {ReactComponent<EntityRendererProps<BasketProduct>>}
     */
    ({element, onSelect, onDelete, onSave, onOperation, product, deleteDialogService}) => {
        return <ClosedBasketContext.Consumer>
            {closed => <ListItem 
                key={element.id} 
                data-testid={`basket-product-list-item-basket-${element.cart_id}-product-${element.product_id}`}
                style={{marginBottom: "0.5em"}}
            >
                <AuxRenderer
                    onSave={onSave}
                    onDelete={onDelete}
                    closed={closed}
                    entity={element}
                    productService={product}
                    deleteDialogService={deleteDialogService}
                />
            </ListItem>}
        </ClosedBasketContext.Consumer>;
    }
);

export default ProductListRenderer;
