import React, { useState } from "react";
import { EntityRendererProps, inject } from "js-react-components";
import { Category } from "../../../domain/category";
import { ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, Button, Badge, ButtonGroup } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import OperationsMenu from "../../auxiliary/operations-menu.component";
import settingsContext from "../../../contexts/settings.context";
import servicesContext from "../../../contexts/services.context";

const CategoryOperationsList = ({category, onDelete, onEdit, onShowProducts}) => <OperationsMenu
    operations={[
        {
            label: "Modifica", 
            handler: onEdit
        },
        {
            label: "Cancella", 
            handler: onDelete
        },
        {
            label: "Mostra prodotti", 
            handler: onShowProducts,
            disabled: true
        }
    ]}
/>

/**
 * @param {EntityRendererProps<Category>} param0 
 */
export default inject(
    settingsContext,
    ["smallScreen"],
    inject(
        servicesContext,
        ["windowService"],
        ({
            element, onOperation, onSelect, onDelete, smallScreen, windowService
        }) => {
            const avatar = <Avatar>
                <Label />
            </Avatar>;
            return <ListItem 
                key={element.id} 
                data-testid={`category-list-${element.id}-container`}
            >
                <ListItemAvatar>
                    {Boolean(element.woocommerce_id) ? <Badge color="primary" badgeContent="W">{avatar}</Badge> : avatar}
                </ListItemAvatar>
                <ListItemText 
                    primary={element.name} 
                    secondary={element.description} 
                    primaryTypographyProps={{
                        "data-testid": `category-list-${element.id}`
                    }}
                    secondaryTypographyProps={{
                        "data-testid": `category-list-${element.id}-name`
                    }}
                />
                <ListItemSecondaryAction>
                    {!smallScreen ? <ButtonGroup>
                        <Button 
                            variant="text" 
                            color="primary" 
                            type="button" 
                            onClick={() => onSelect(element)}
                            data-testid={`category-${element.id}-select`}
                        >
                            Modifica
                        </Button>
                        <Button 
                            variant="text" 
                            color="secondary" 
                            type="button" 
                            onClick={() => onOperation("delete", element)}
                            data-testid={`category-${element.id}-delete`}
                        >
                            Cancella
                        </Button>
                        <Button
                            variant="text" 
                            color="secondary" 
                            type="button" 
                            data-testid={`category-${element.id}-products`}
                            onClick={() => windowService.href(`/category/${element.id}/products`)}
                        >
                            Prodotti
                        </Button>
                    </ButtonGroup> : <CategoryOperationsList
                        category={element}
                        onDelete={() => onOperation("delete", element)}
                        onEdit={() => onSelect(element)}
                        onShowProducts={() => windowService.href(`/category/${element.id}/products`)}
                    />}
                </ListItemSecondaryAction>
            </ListItem>
        }
    )
)