import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeProductCategoryService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => `/product/${e.filterValue}/category`}).build(),
        handle("count").with({method: "GET", path: (d, e) => `/product/${e.filterValue}/category/count`}).build(),
        handle("save").with({method: "POST", path: e => `/product/${e.product_id}/category`}).build(),
        handle("delete").with({method: "DELETE", path: e => `/product/${e.product_id}/category/${e.category_id}`}).build()
    ];

    return serve(handlers).using(requestManager).build();
}