export default class WindowService {

    /**
     * @type {Window}
     */
    _window;

    constructor(window) {
        this._window = window;
    }

    href(to) {
        this._window.location.href = `#${to}`;
    }
}