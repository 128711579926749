import {handle, serve, EntityService, HttpOperation} from "js-generic-utilities";

/**
 * @param {{requestManager: RequestManager}} param0 
 * @return {EntityService<HttpOperation>}
 */
export default function makeAttributeValueService({requestManager}) {
    const handlers = [
        handle("get").with({method: "GET", path: (d, e) => (e.filterAttribute === "variant_feature_id") ? `/variant_feature/${e.filterValue}/value` : `/variant_feature_value`}).build(),
        handle("count").with({method: "GET", path: (d, e) => (e.filterAttribute === "variant_feature_id") ? `/variant_feature/${e.filterValue}/value/count` : `/variant_feature_value/count`}).build(),
        handle("save").with({method: "POST", path: e => `/variant_feature/${e.variant_feature_id}/value`}).build(),
        handle("delete").with({method: "DELETE", path: e => e.permalink}).build(),
        handle("update").with({method: "POST", path: e => e.permalink}).build()
    ];

    return serve(handlers).using(requestManager).build();
}