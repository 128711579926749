import {EntityMeta} from "js-generic-utilities";
import {getDefaultMeta} from "./auxiliary/domain-misc-functions";
import regexes from "./auxiliary/regex-repository";

/**
 * @typedef {"list" | "grid"} CustomerViews
 * @typedef {"name"} CustomerIndices
 * @typedef {"name"} CustomerSorters
 * @typedef {"id" | "name" | "points_code" | "baskets_count" | "address" | "phone"} CustomerAttributes
 * @typedef {{
 *    id: number,
 *    name: string,
 *    points_code: number|string,
 *    baskets_count?: number,
 *    address?: string,
 *    phone?: string
 * }} Customer
 */

/**
 * @type {EntityMeta<Customer>}
 */
const meta = getDefaultMeta();

export class CustomerWrapper {
    
    /**
     * @type {Customer}
     */
    _d;

    constructor(d) {
        this._d = d;
    }

    validate(name) {
        switch(name) {
            case "name":
                return this.validateName();
            case "address":
                return this.validateAddress();
            case "phone":
                return this.validatePhone();
            default:
                return this.validateCode();
        }
    }

    validateName() {
        return this._d.name && regexes.alphanumeric.test(this._d.name);
    }

    validateAddress() {
        return this._d.address && regexes.text.test(this._d.address);
    }

    validatePhone() {
        return this._d.phone && regexes.phone.test(this._d.phone);
    }

    validateCode() {
        return this._d.points_code && regexes.alphanumeric.test(this._d.points_code);
    }

    validateAll() {
        return this.validateName() && this.validateCode();
    }
}

export default meta;
