import React, {useState} from "react";
import AttributeMeta, {Attribute} from "../../../../../domain/attribute";
import {Product} from "../../../../../domain/product";
import clsx from "clsx";
import AttributeSearchContext from "../../../../attribute/search/attribute-search.context";
import ComponentsContext from "../../../../../contexts/components.context";
import {CollectionUtilityWrapper} from "js-generic-utilities";
import {Container, Grid, Chip, Button, Typography, Divider, Box, withStyles, makeStyles, Badge, CircularProgress} from "@material-ui/core";
import {inject, ReactComponent} from "js-react-components";
import {Alert} from "@material-ui/lab";
import {Delete} from "@material-ui/icons";
import LoadingComponent from "../../../../auxiliary/loading.component";

const useStyles = makeStyles(theme => ({
    header: {
        color: "rgb(111, 111, 111)",
        textTransform: "uppercase"
    },
    mainHeader: {
        fontWeight: "bold"
    },
    centered: {
        textAlign: "center"
    },
    subheader: {
        color: "rgb(111, 111, 111)",
        fontWeight: "bold"
    }
}));

/**
 * @param {CollectionUtilityWrapper<Attribute>} attributes 
 */
const countVariants = attributes => attributes.count() ? attributes.mapReduce(a => parseInt(a.values_number), (a, b) => a * b, 1) : 0;

const SelectedAttributesViewElement = ({attribute, onRemove}) => <Chip 
    label={attribute.name} 
    onDelete={onRemove}
    variant="outlined"
    color="secondary"
    size="medium"
    deleteIcon={<Delete data-testid={`selected-attribute-${attribute.id}-delete`} />}
    data-testid={`selected-attribute-${attribute.id}`}
/>;

/**
 * @param {{attributes: Attribute[], onRemove: (a: Attribute) => void}} param0 
 */
const SelectedAttributesView = ({attributes, onRemove}) => {
    const classes = useStyles();
    return  <Grid container spacing={2} justify="center">
        {attributes.length ? attributes.map(a => <Grid item key={a.id}>
            <SelectedAttributesViewElement attribute={a} onRemove={() => onRemove(a)} />
        </Grid>) : <Grid item xs>
            <Typography component="p" variant="body1" className={classes.centered}>
                Nessun attributo selezionato.    
            </Typography>
        </Grid>}
    </Grid>;
};

/**
 * @param {Attribute[]} data 
 * @return {CollectionUtilityWrapper<Attribute>}
 */
const wrap = data => CollectionUtilityWrapper.wrap(data, AttributeMeta.matcher);

const VariantGenerationAttributeSelection = inject(
    ComponentsContext,
    ["VariantGenerationAttributeSearch"],
    /**
         * @param {{
            *      baseProduct: Product,
            *      onGenerate: (attributes: Attribute[]) => void,
            *      VariantGenerationAttributeSearch: ReactComponent<{onSelect: (attribute: Attribute) => void}>
            * }} param0
            */
           ({
            VariantGenerationAttributeSearch, onGenerate, baseProduct, noHeader, loading
           }) => {
               const classes = useStyles();
               const [attributes, setAttributes] = useState(wrap([]));
       
               const onAttributeAdd = a => setAttributes(attrs => attrs.push(a));
               const onAttributeRemove = a => setAttributes(attrs => attrs.remove(a));
               const onConfirmGeneration = () => setAttributes(attrs => {
                   onGenerate(attrs.unwrap());
                   return attrs;
               })
       
               return <Container maxWidth={false} component="article">
                   <Box position="relative">
                    {loading && <LoadingComponent size="3em" label="Generazione in corso..." />}
                    <Grid container spacing={2}>
                        {!noHeader && <Grid item xs={12} component="header">
                            <Typography 
                                component="h4" 
                                variant="h4" 
                                className={clsx([classes.header, classes.mainHeader, classes.centered])}
                                gutterBottom
                                data-testid="variant-generation-title"
                            >
                                Generazione varianti prodotto &nbsp;
                                <span data-testid="variant-generation-title-product">
                                    {baseProduct.name}
                                </span>
                            </Typography>
                            <Typography 
                                    component="h5"
                                    variant="h5"
                                    className={clsx([classes.centered, classes.subheader])}
                                    gutterBottom
                                    data-testid="variant-generation-subtitle"
                                >
                                {attributes.count()} attributi selezionati (<span data-testid="variant-generation-number-variants">{countVariants(attributes)}</span> varianti)
                            </Typography>
                        </Grid>}
                        <Grid item xs={12} component="section">
                            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="0.5em">
                                <Badge variant="standard" color="primary" badgeContent={attributes.count()}>
                                    <Typography component="header" variant="h4" className={clsx([classes.subheader])}>
                                        Attributi selezionati (per un totale di {countVariants(attributes) || "?"} varianti)
                                    </Typography>
                                </Badge>
                                <Button
                                    variant="outlined" 
                                    color="primary" 
                                    disabled={!attributes.count()}
                                    onClick={onConfirmGeneration}
                                    data-testid="variant-generation-confirm"
                                >
                                    Genera varianti
                                </Button>
                            </Box>
                            <Box component="article" marginTop="1em">
                                <Container 
                                    maxWidth={false} 
                                    disableGutters 
                                    data-testid="variant-generation-selected-attributes-wrapper"
                                >
                                    <SelectedAttributesView 
                                        attributes={attributes.unwrap()} 
                                        onRemove={onAttributeRemove} 
                                    />
                                </Container>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} data-testid="variant-generation-attribute-search-wrapper" component="section">
                            <Container maxWidth={false} disableGutters>
                                <Grid container alignItems="center" justify="space-between">
                                    <Grid item>
                                        <Typography component="header" variant="h4" gutterBottom className={clsx([classes.subheader])}>
                                            Aggiunta nuovo attributo
                                        </Typography>
                                    </Grid>
                                    <AttributeSearchContext.Provider value={{selected: attributes.unwrap().map(a => a.id)}}>
                                        <VariantGenerationAttributeSearch onSelect={onAttributeAdd} />
                                    </AttributeSearchContext.Provider>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                   </Box>
               </Container>
           }
);

export default VariantGenerationAttributeSelection;