import React, {useState, memo} from "react";
import ProductCategoryEditor from "../../category/product-category-editor.component";
import ProductDetailsEditor from "../product-details-entity-editor.component";
import ProductImageEditor from "../../image/product-image-editor.component";
import ProductOverview from "../../entity-renderers/product-overview.component";
import ComponentsContext from "../../../../contexts/components.context";
import ServicesContext from "../../../../contexts/services.context";
import {inject} from "js-react-components";
import {Grid, Container, Divider, Tabs, Tab} from "@material-ui/core";
import WindowService from "../../../../services/auxiliary/window-service";
import {wrap} from "../../../../domain/product";
import LoadingComponent from "../../../auxiliary/loading.component";
import moment from "moment";

const SectionSelector = memo(
    ({onSelect, selected}) => <Tabs value={selected} onChange={(e, v) => onSelect(v)}>
        {([
            "Dati", "Costi", "Categorie", "Immagini", "Statistiche", "Caricamenti e resi"
        ]).map((s, i) => <Tab 
            value={i}
            label={s}
            key={s}
        />)}
    </Tabs>,
    (p, n) => p.selected === n.selected
);

const Loader = () => <LoadingComponent size="3em" label="Caricamento..." />

const Component = inject(
    ComponentsContext,
    ["ProductStat", "ProductQuantityAlterationAlt", "ProductCost"],
    inject(
        ServicesContext,
        ["windowService"],
        /**
             * @param {{windowService: WindowService}}
             */
            ({
                element, loading, onSave, noDetails, ProductStat, ProductCost, onOperation, ProductQuantityAlterationAlt
            }) => {
                const [section, setSection] = useState(0);
                const wrapped = element && wrap(element);
                return element ? <Container maxWidth={false} disableGutters style={{position: "relative"}}>
                    {loading && <Loader />}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ProductOverview product={element} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <SectionSelector 
                                    onSelect={setSection} 
                                    selected={section} 
                                    noDetails={noDetails}
                                    hasVariants={wrapped.is("variable")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {(section === 0) && <ProductDetailsEditor element={element} onSave={onSave} />}
                                {(section === 1) && <ProductCost productId={element.id} />}
                                {(section === 2) && <ProductCategoryEditor product={element} />}
                                {(section === 3) && <ProductImageEditor product={element} />}
                                {(section === 4) && <ProductStat
                                    filterAttribute="product_id"
                                    filterValue={element.id}
                                    CollectionRendererProps={{product: element}}
                                />}
                                {(section === 5) && !wrapped.is("variable") && <ProductQuantityAlterationAlt
                                    productId={element.id}
                                    defaultView="table"
                                    ComposerProps={{
                                        EditRendererProps: {
                                            element: {product_id: element.id, date: moment().format("YYYY-MM-DD")}
                                        }
                                    }}
                                />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container> : null;
            }
    )
);

export default Component;
