import makeProductBaseComponent from "../product-base-component";
import {EntityService} from "js-generic-utilities";
import {ReactComponent, ComposerProps} from "js-react-components";
import {Grid, Container, Box, makeStyles} from "@material-ui/core";
import ProductSearchCard from "../entity-renderers/product-search-card.component";
import ProductSearchPaginator from "./product-search-paginator.component";
import { Fragment } from "react";
import { makeProductListRenderer } from "../entity-renderers/product-list-renderer.component";

const useStyles = makeStyles(theme => ({
    collectionWrapper: {
        width: "75%",
        margin: "auto",
        zIndex: 4
    },
    externalWrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center"
    }
}));

/**
 * @typedef {{
 *      service: EntityService<"get">,
 *      onSelect: (product: Product) => void
 * }} ProductSearchComponentProps 
 */

/**
 * @type {ReactComponent<ComposerProps>} 
 */
const SearchComposer = ({collection, paginator, searchBar, filterer, loader}) => {
    const classes = useStyles();
    return <Fragment>
        {loader}
        <Grid item>
            {searchBar || filterer}
        </Grid>
        <Grid item xs={12} data-testid="product-search-collection-wrapper">
            <Box className={classes.externalWrapper}>
                {paginator}
                <Box className={classes.collectionWrapper}>
                    {collection}
                </Box>
            </Box>
        </Grid>
    </Fragment>
}

export function AltSearchComposer({collection, paginator, searchBar, filterer, loader}) {
    return <Box position="relative">
        {loader}
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {searchBar || filterer}
            </Grid>
            <Grid item xs={12}>
                {collection}
            </Grid>
            <Grid item container justify="center" xs={12}>
                <Grid item>
                    {paginator}
                </Grid>
            </Grid>
        </Grid>
    </Box>
}

/**
 * @param {ProductSearchComponentProps} param0 
 */
export default function makeProductSearchComponent({
    service, 
    EntityRenderer, 
    Paginator, 
    gridOptions, 
    defaultView, 
    itemsPerPage, 
    composer, 
    select, 
    selectable
}) {
    const BaseComponent = makeProductBaseComponent({
        Paginator: Paginator || ProductSearchPaginator,
        service, 
        gridOptions,
        defaultView,
        composer: composer || SearchComposer,
        ListEntityRenderer: makeProductListRenderer({
            noDelete: true, 
            select, 
            selectable
        }),
        EntityRenderer: EntityRenderer || ProductSearchCard
    });
    return ({onSelect, filterAttribute, filterValue}) => <BaseComponent 
        defaultView={defaultView || "list"}
        filterAttribute={filterAttribute}
        filterValue={filterValue}
        operations={[
            {
                name: "select",
                label: "Seleziona",
                handler: onSelect
            }
        ]} 
    />;
}