import React, { useState, useEffect } from "react";
import { inject } from "js-react-components";
import ComponentsContext from "../../../contexts/components.context";
import ServicesContext from "../../../contexts/services.context";
import { Grid, Typography, Container, Box, Button, Popover, Modal, Paper, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import {Product} from "../../../domain/product";
import {ObserverPool, EntityService, HttpOperation, observe, remove, notify} from "js-generic-utilities";
import ImageSearchContext from "../../image/search-component/context/image-search-context";
import ProductImageSearch from "./product-image-search.component";

const aux = imgs => imgs ? imgs.map(c => ({id: c.image_id})) : [];
const ImgSearchTrigger = ({onClick}) => <Button 
    fullWidth 
    variant="outlined" 
    color="primary" 
    onClick={onClick}
>
    Aggiungi immagine
</Button>;

const Component = inject(
    ComponentsContext,
    ["ImageSearch", "ProductImage"],
    inject(
        ServicesContext,
        ["productImage", "productImageComponent"],
        /**
         * @param {{
         *      ProductImage: Function,
         *      ImageSearch: Function,
         *      productImage: EntityService<HttpOperation>,
         *      productImageComponent: ObserverPool<"refresh">,
         *      product: Product
         * }} param0
         */
        ({ImageSearch, ProductImage, productImageComponent, productImage, product}) => {

            const [imgs, setImgs] = useState(null);

            useEffect(() => {
                console.log("setting observer on productCategory");
                if(imgs === null) {
                    observe(productImage).on("get").with(setImgs);
                    setImgs([]);
                    return () => remove(setImgs).from(productImage).on("get").do();
                }
            }, []);

            const onImageSelect = c => {
                productImage
                    .serve("save", {product_id: product.id, image_id: c.id})
                    .then(() => notify(productImageComponent).of("refresh").withNull())
                    .catch(mex => console.log(mex))
            }

            console.log(imgs);

            return (imgs !== null) ? <ImageSearchContext.Provider value={{selectedImages: aux(imgs)}}>
                <Container maxWidth={false} disableGutters>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {/*<Typography component="header" variant="h4" gutterBottom style={{textAlign: "center", background: "rgb(248, 248, 248)", color: "grey", fontWeight: "bold", padding: "0.5em", borderBottom: "1px solid rgb(222, 222, 222)"}}>
                                Immagini attuali
        </Typography>*/}
                            <Box component="article">
                                <ProductImage productId={product.id} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ProductImageSearch 
                                Trigger={ImgSearchTrigger} 
                                onSelect={onImageSelect} 
                            />  
                        </Grid>
                    </Grid>
                </Container>
            </ImageSearchContext.Provider> : null;
        }
    )
)

export default Component;