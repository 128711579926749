import {render, EntityBaseComponentBuilder, makePaginator, ListCollectionRenderer} from "js-react-components";
import AlertMeta, {Alert} from "../../domain/alert";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import {Grid} from "@material-ui/core";
import Renderer from "./entity-renderers/alert-list-entity-renderer.component";
import {makeDateFilterer} from "../auxiliary/filterers/simple-date-filterer.component";
import DefaultLoader from "../auxiliary/default-loader.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import { texts } from "../../constants";
import {Warning} from "@material-ui/icons";

/**
 * @typedef {"name" | "content" | "timestamp"} AlertAttributes
 * @typedef {"name"} AlertIndices
 * @typedef {"name" | "content"} AlertSorters
 * @typedef {"list"} AlertViews
 */

const Composer = makeComposer({config: {hideAdder: true, filterLabel: "Filtraggio per data"}});

const wrapOptions = {
    avatarSize: 100,
    title: texts.missing_elements_title("notifica", true),
    body: "Puoi filtrarle per mese usando l'apposito pulsante",
    Icon: Warning
};


const DateFilterer = makeDateFilterer({
    attribute: "created_at",
    resolution: "months",
    testID: "alert-date-filterer"
});

export default function makeAlertBaseComponent({service, deleteDialogService, itemsPerPage}) {

    /**
     * @type {EntityBaseComponentBuilder<"refresh", Alert, AlertViews, AlertIndices, AlertSorters, AlertAttributes>}
     */
    const builder = render(service);

    builder
        .withMeta(AlertMeta)
        .withList({
            Collection: wrapRenderer(ListCollectionRenderer, wrapOptions),
            Entity: Renderer
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .configure({
            editing: false,
            adding: false,
            deleting: false
        })
        .withComponents({
            Filterer: DateFilterer,
            Sorter: () => null,
            Loader: DefaultLoader,
            Paginator: makePaginator({}, "attribute-paginator", {
                hideItemsPerPage: true,
                buttons: true,
                defaultItemsPerPage: itemsPerPage || 9,
                allowFirstLastPages: true
            })
        })
        .withBounds({
            start: 0, 
            end: itemsPerPage || 9
        })
        .withDefaultView("list");

    builder.withOperations([
        {
            name: "delete",
            handler: (element, {onDelete}) => deleteDialogService.show({
                message: "Confermare la cancellazione della notifica?",
                onConfirm: () => onDelete(element)
            })
        }
    ])
    
    return builder.compose(Composer);
}