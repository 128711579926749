import React from "react";
import {
    EntityBaseComponentBuilder, 
    render, 
    ComposerProps, 
    ReactComponent, 
    makeGridRenderer,
    EntityRendererProps,
    makePaginator,
    inject
} from "js-react-components";
import {EntityService, HttpOperation, ObserverPool} from "js-generic-utilities";
import ProductCategoryMeta, {ProductCategory, ProductCategoryAttributes, ProductCategoryIndices, ProductCategorySorters} from "../../domain/product-category";
import CategoryChipDelayedRenderer from "../category/entity-renderers/category-chip-delayed-renderer.component";
import LoadingComponent from "../auxiliary/loading.component";
import { Box, CircularProgress } from "@material-ui/core";
import AsyncEntityLoader from "../auxiliary/async-entity-loader/async-entity-loader.component";
import servicesContext from "../../contexts/services.context";
import CategoryCard from "../category/entity-renderers/category-card-renderer.component";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import { Label } from "@material-ui/icons";

/**
 * @type {ReactComponent<EntityRendererProps<ProductCategory>>} 
 */
/*const GridRenderer = ({onDelete, element}) => {
    return (typeof(element.category_id) === "number") ? <CategoryChipDelayedRenderer
        key={`${element.product_id}-${element.category_id}`}
        testId={`product-${element.product_id}-category-${element.category_id}-chip`}
        onRemove={() => onDelete(element)} 
        id={element.category_id}
    /> : null;
};*/

const GridRenderer = inject(
    servicesContext,
    ["category"],
    ({onDelete, element, category}) => {
        return (typeof(element.category_id) === "number") ? <AsyncEntityLoader
            id={element.category_id}
            onGet={id => category.serve("get", null, {filterAttribute: "id", filterValue: id})}
            Renderer={CategoryCard}
            RendererProps={{
                onDelete: () => onDelete(element),
                deleteOnly: true,
                useOnDelete: true
            }}
            LoadingComponent={CircularProgress}
        /> : null;
    }
)

/**
 * @param {ComposerProps} param0 
 */
const Composer = ({collection, loader}) => <Box>
    {collection}
    {loader}
</Box>;

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />

/**
 * @typedef {"grid"} ProductCategoryViews
 * @typedef {EntityBaseComponentBuilder<"refresh", ProductCategory, ProductCategoryViews, ProductCategoryIndices, ProductCategorySorters, ProductCategoryAttributes>} ProductCategoryBaseComponentBuilder
 * @param {{
 *      componentService: ObserverPool<"refresh">,
 *      service: EntityService<HttpOperation>
 * }} param0 
 */
export default function makeComponent({service, alertService, componentService}) {
    /**
     * @type {ProductCategoryBaseComponentBuilder}
     */
    let builder = render(service);

    builder.withMeta(ProductCategoryMeta)
        .withGrid({
            Collection: wrapRenderer(makeGridRenderer({
                spacing: 2,
                elementsPerRow: {xs: 6, sm: 3}
            }), {
                title: "Nessuna categoria selezionata", 
                body: "Puoi aggiungerne una mediante l'apposito pulsante",
                Icon: Label,
                avatarSize: 100
            }),
            Entity: GridRenderer
        })
        .withFilter({
            attribute: "product_id"
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withService(componentService)
        .withDefaultView("grid")
        .withComponents({
            Alert: () => null,
            Loader: Loader,
            Filterer: () => null,
            Sorter: () => null,
            Paginator: makePaginator({}, "product-category-paginator", {defaultItemsPerPage: 99999})
        })
        .withBounds({
            start: 0, end: 99999
        })
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: "Categoria prodotto modificata.",
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: "Categoria prodotto cancellata.",
                severity: "success"
            })
        });

    const Component = builder.compose(Composer);
    return ({productId}) => <Component filterValue={productId} />;
}
