import React from "react";
import {
    ReactComponent, ComposerProps, EntityOperation, 
    makeEntityListRenderer, EntityRendererProps,
    PaginatorProps,
    inject,
    makeTableRenderer
} from "js-react-components";
import {EntityService, HttpOperation} from "js-generic-utilities";
import ProductMeta, {Product, ProductAttributes, ProductIndices, ProductSorters} from "../../domain/product";
import ProductCard from "./entity-renderers/product-card.component";
import {
    render, 
    EntityBaseComponentBuilder, 
    makeSearchBar, 
    makePaginator, 
    makeGridRenderer,
    ListCollectionRenderer as DefaultListCollectionRenderer
} from "js-react-components";
import ProductListRenderer from "./entity-renderers/product-list-renderer.component";
import ProductTableRenderer from "./entity-renderers/product-table-renderer.component";
import LoadingComponent from "../auxiliary/loading.component";
import {makeComposer} from "../auxiliary/base-composer.component";
import {InlineTrigger, FabTrigger} from "../auxiliary/fab-trigger.component";
import ServicesContext from "../../contexts/services.context";
import {Typography} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import {wrapRenderer} from "../auxiliary/missing-elements.component";
import {texts} from "../../constants";
import {makeMultiAttributeFilterer} from "../auxiliary/filterers/multi-attribute-filterer.component";
import ProductEditorRenderer from "./entity-editors/product-editor-renderer.component.js";
import settingsContext from "../../contexts/settings.context";

const ProductComposer = makeComposer({
    Adder: inject(
        ServicesContext,
        ["windowService"],
        inject(
            settingsContext,
            ["smallScreen"],
            ({windowService, smallScreen}) => smallScreen ? <FabTrigger 
                onClick={() => windowService.href("/product/add")} 
            /> : <InlineTrigger
                onClick={() => windowService.href("/product/add")} 
            />
        )
    )
});

const titles = {
    name: "Nome",
    quantity: "Quantità",
    actions: "Azioni",
    cost: "Costi",
    attributes: "Attributi"
};

/**
 * @typedef {"grid" | "list" | "table"} ProductViews 
 * @typedef {EntityBaseComponentBuilder<"refresh", Product, ProductViews, ProductIndices, ProductSorters, ProductAttributes>} ProductBaseComponentBuilder
 */

const Loader = ({message}) => <LoadingComponent label={message} size="3rem" />
//const HeadCellRenderer = ({attribute}) => <Typography variant="body1" component="header" style={{fontWeight: "bold"}}>{titles[attribute]}</Typography>;
const wrapOptions = {
    title: texts.missing_elements_title("prodotto"),
    body: texts.missing_elements_body
};

/**
 * @typedef {"save" | "get" | "count" | "delete" | "generate_variants"} ProductOperation
 */

const DefaultProductListRenderer = props => <ProductListRenderer {...props} select selectable />

/**
 * @param {{
 *      service: EntityService<ProductOperation>,
 *      composer: ReactComponent<ComposerProps>,
 *      operations?: EntityOperation<Product>[],
 *      EntityRenderer: ReactComponent<EntityRendererProps<Product>>,
 *      Paginator: ReactComponent<PaginatorProps>,
 *      itemsPerPage?: number,
 *      defaultView?: "list" | "grid",
 *      filters?: {
 *          attribute: string,
 *          value: string
 *      }
 * }} param0 
 */
export default function makeProductBaseComponent({
    service, composer, operations, EntityRenderer, ListEntityRenderer,
    Paginator, itemsPerPage, defaultView, filters, Editor, gridOptions,
    basketProductService, basketSideViewService, ListCollectionRenderer,
    windowService, TableEntityRenderer, searchBar, deleteDialogService,
    alertService
}) {
    
    /**
     * @type {ProductBaseComponentBuilder}
     */
    let builder = render(service);

    const InnerGridRenderer = makeGridRenderer(gridOptions || {
        spacing: 1, 
        elementsPerRow: {xs: 12, sm: 3, md: 3}
    });

    const InnerTableRenderer = makeTableRenderer([
        {
            name: "name",
            label: "Nome",
            sortable: true
        }, 
        {
            name: "quantity",
            label: "Qtà",
            sortable: true
        },
        {
            name: "cost",
            label: "Costo",
            sortable: false
        },
        {
            name: "actions",
            label: "Azioni",
            sortable: false
        }
    ], {
        sorting: true
    }, "", "");

    builder.withMeta(ProductMeta)
        .withGrid({
            Entity: EntityRenderer || ProductCard,
            Collection: wrapRenderer(InnerGridRenderer, {
                ...wrapOptions,
                Icon: Warning
            })
        })
        .withList({
            Entity: ListEntityRenderer || ProductListRenderer,
            Collection: wrapRenderer(ListCollectionRenderer || DefaultListCollectionRenderer, {
                ...wrapOptions,
                Icon: Warning
            })
        })
        .withTable({
            Collection: wrapRenderer(InnerTableRenderer, {
                ...wrapOptions,
                avatarSize: 100,
                Icon: Warning
            }),
            Entity: TableEntityRenderer || ProductTableRenderer
        })
        .configure({
            editing: false,
            adding: false,
            deleting: false
        })
        .withBounds({
            start: 0, 
            end: itemsPerPage || 24
        })
        .withTexts({
            onDeleteLoading: "Attendere",
            onSaveLoading: "Attendere",
            onGetLoading: "Attendere"
        })
        .withDefaultView(defaultView || "grid")
        .withComponents({
            SearchBar: searchBar && makeSearchBar("product-search-bar"),
            Paginator: Paginator || makePaginator({}, "product-paginator", {
                defaultItemsPerPage: itemsPerPage || 6,
                hideItemsPerPage: true,
                buttons: true,
                allowFirstLastPages: true,
                hideTotalItems: true
            }),
            Filterer: makeMultiAttributeFilterer({
                testID: "product-multi-attribute-filterer",
                filters: [
                    {
                        attribute: "name", 
                        label: "Nome"
                    },
                    {
                        attribute: "code", 
                        label: "Codice"
                    }
                ]
            }),
            Sorter: () => null,
            Loader: Loader,
            Alert: () => null
        })
        .withCallbacks({
            onAfterSave: e => alertService.show({
                message: "Prodotto modificato.",
                severity: "success"
            }),
            onAfterDelete: e => alertService.show({
                message: "Prodotto cancellato.",
                severity: "success"
            }),
            onError: e => alertService.show({
                message: e,
                severity: "error"
            })
        });
    
    let ops = [
        {
            name: "addToActiveCart",
            label: "addToActiveCart",
            handler: e => basketProductService && basketProductService.serve("save", e).then(() => {
                basketSideViewService.reload();
            })
        },
        {
            name: "addVariant",
            label: "addVariant",
            handler: e => windowService && windowService.href(`/variable_product/${e.id}/variant/add`)
        },
        {
            name: "delete",
            handler: (e, {onDelete}) => deleteDialogService.show({
                message: `Confermare la cancellazione del prodotto ${e.name}?`, 
                onConfirm: () => onDelete(e)
            })
        },
        {
            name: "generateVariants",
            handler: ({product, attributes}, {onSave}) => service
                .serve("generate_variants", {
                    product, 
                    variant_features: attributes.map(a => a.id)
                })
                .then(() => onSave(product))
                .catch(mex => console.log(mex))
        }
    ];

    if(operations) {
        ops = ops.concat(operations);
    }

    builder.withOperations(ops);

    if(filters) {
        builder.withFilter(filters);
    }

    builder.editEntityWith(Editor || ProductEditorRenderer);
    
    if(searchBar) {
        builder.withSearchBarAttribute({
            label: "Nome prodotto",
            attribute: "name"
        });
    }
    
    return builder.compose(composer || ProductComposer);
}