import React, { useEffect, memo, useState, useRef } from "react";
import makeProductSearchComponent from "../search/product-search.component";
import ProductMeta, {Product} from "../../../domain/product";
import {inject, ReactComponent} from "js-react-components";
import {EntityService} from "js-generic-utilities";
import ServicesContext from "../../../contexts/services.context";
import {e} from "../../../utils/misc-utils-functions";
import {Grid, FormLabel, Button, CircularProgress} from "@material-ui/core";
import ComponentsContext from "../../../contexts/components.context";
import ProductSearchCard from "../entity-renderers/product-search-card.component";
import ProductShortOverview from "../entity-renderers/product-overview.component";

const Component = memo(
    /**
     * @param {{
     *      service: EntityService<"get">, 
     *      product: Product
     * }} param0 
     */
    ({service, product}) => {
        const [baseProduct, setBaseProduct] = useState(null);
    
        useEffect(() => {
            let id = product.variable_product_id;
            console.log(`VariableProductAsyncPrinter.<effect>: loading base product with id ${id}`);
            service.serve("get", null, {filterAttribute: "id", filterValue: id})
                   .then(v => setBaseProduct(v instanceof Array ? v[0] : v))
                   .catch(mex => console.log(mex))
        }, [product]);
    
        return <Grid container spacing={1}>
            <Grid item xs={12}>
                {baseProduct ? <ProductShortOverview 
                    baseId="variable-product-async-printer-card" 
                    product={baseProduct}
                /> : <CircularProgress
                    data-testid="variable-product-async-loader" 
                />}
            </Grid>
        </Grid>
    },
    (p, n) => e(p.product, n.product)
);
export default Component;